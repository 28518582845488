import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

// models
import { peakStrings } from "src/models/peak-filters-values";
import ServicesRequest from "src/models/services-request.model";
import ServicesResponseModel from "src/models/services-response.model";

// services
import getServices from "src/services/services.service";

// components
// import ResultsTable from './results-table';
import ChartTabs from "./charts/chart-tabs";

const detailsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: { minWidth: "140px" }
  })
);
const progressStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative"
    },
    progress: {
      position: "absolute",
      top: "50%",
      marginTop: -12,
      marginLeft: theme.spacing(16)
    }
  })
);

export default function RoutesTable() {
  const listClasses = detailsListStyles();
  const progressClasses = progressStyles();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [periodServices, setPeriodServices] = React.useState<
    ServicesResponseModel
  >();

  const params: ServicesRequest = useParams<any>();

  useEffect(() => {
    setError(null);
    setLoading(true);

    getServices(params).then((res: ServicesResponseModel | any) => {
      setLoading(false);

      if (res.error) {
        setError(res.error);
      } else {
        setPeriodServices(res);
      }
    });
  }, [params]);

  return (
    <div>
      {error && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          An error has occurred. Please try again, and if the problem persists,
          contact your help desk or system administrator.{" "}
          <p>
            {" "}
            (<strong>{error}</strong>)
          </p>
        </Alert>
      )}
      <h1>Route</h1>
      <List>
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Stop pattern:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.stopping_pattern} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Start date range A:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.start_date} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>End date range A:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.end_date} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Start date range B:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.start_date_to_compare} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>End date range B:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.end_date_to_compare} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Peak time:</strong>
          </ListItemAvatar>
          <ListItemText
            secondary={
              peakStrings.find(p => p.value === Number(params.peak_time))?.name
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Headcode:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.headcode} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Days:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.days} />
        </ListItem>
      </List>

      {loading && (
        <Box mt={3}>
          <Alert severity="info" className={progressClasses.wrapper}>
            Loading results ...{" "}
            <CircularProgress size={24} className={progressClasses.progress} />
          </Alert>
        </Box>
      )}

      {periodServices && params && (
        <Box mt={3}>
          <ChartTabs data={periodServices} />
        </Box>
      )}
    </div>
  );
}
