import React from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import WarningIcon from "@material-ui/icons/Warning";

// models
import TrainJourneysModel from "./../../../models/train-journeys.model";

// components
import FaultsTable from "./faults-table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative"
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    }
  })
);

export default function FaultsDialog(journeyFaults: {
  criticalSystems: TrainJourneysModel["criticalSystems"];
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {journeyFaults.criticalSystems.length > 0 ? (
        <div>
          {" "}
          <WarningIcon
            style={{ color: red[500], cursor: "hand" }}
            onClick={handleClickOpen}
          />
          <Typography
            variant="caption"
            display="inline"
            align="right"
            gutterBottom
          >
            ({journeyFaults.criticalSystems.length})
          </Typography>
        </div>
      ) : (
        "None"
      )}

      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}></Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <FaultsTable data={journeyFaults.criticalSystems} />
      </Dialog>
    </div>
  );
}
