import React from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";

// material
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Typography from "@material-ui/core/Typography";

// components
import FiltersForm from "./filters-form/filters-form";
import RoutesTable from "./routes-table/routes-table";

const breadcrumbNameMap: { [key: string]: string } = {
  "/": "Search for a route",
  "/routes": "Route results"
};

export default function CompareServices() {
  let { path } = useRouteMatch();
  let currentBreadcrumb: Array<string> = [];

  return (
    <div>
      {/* <Breadcrumb /> */}
      <div>
        <Route>
          {({ location }) => {
            const pathnames = location.pathname.split("/").filter(x => x);
            // remove dashboard/compare-services path
            pathnames.splice(0, 2);
            currentBreadcrumb = [];

            pathnames.forEach((value, index) => {
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              if (breadcrumbNameMap[to]) {
                currentBreadcrumb.push(to);
              }
            });

            return (
              <>
                {currentBreadcrumb.length > 1 && (
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {currentBreadcrumb.map((value, index) => {
                      const last = index === currentBreadcrumb.length - 1;
                      return last ? (
                        <Typography color="textPrimary" key={value}>
                          {breadcrumbNameMap[value]}
                        </Typography>
                      ) : (
                        <RouterLink
                          color="inherit"
                          to={`/dashboard/compare-services${value}`}
                          key={value}
                        >
                          {breadcrumbNameMap[value]}
                        </RouterLink>
                      );
                    })}
                  </Breadcrumbs>
                )}

                {currentBreadcrumb.length === 1 && (
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <RouterLink
                      color="inherit"
                      to={`/dashboard/compare-services`}
                    >
                      {breadcrumbNameMap["/"]}
                    </RouterLink>
                    <Typography color="textPrimary" key={"/routes"}>
                      {breadcrumbNameMap["/routes"]}
                    </Typography>
                  </Breadcrumbs>
                )}
              </>
            );
          }}
        </Route>
      </div>
      <Switch>
        <Route exact path={path}>
          <FiltersForm />
        </Route>

        <Route
          path={`${path}/routes/:stopping_pattern/:start_date/:end_date/:start_date_to_compare/:end_date_to_compare/:peak_time/:headcode/:days`}
          children={<RoutesTable />}
        />
      </Switch>
    </div>
  );
}
