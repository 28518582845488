import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "../../../../models/train-journeys.model";

export default function OnTimeGainsLossesChart({
  data,
  dwell,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  dwell: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);

  const xAxisTitle = dwell
    ? "Ontime Gain/Loss Dwell"
    : "Ontime Gain/Loss Section";
  const yAxisTitle = xAxisTitle;
  let categories: Array<string> = [];
  const labelsHeight = dwell ? 50 : 200;
  // set categories
  dwell
    ? (categories = data[0].stops.map(stops => stops.name))
    : data[0].stops.forEach((stop, index) => {
        if (index < data[0].stops.length - 1) {
          categories.push(`${stop.name} - ${data[0].stops[index + 1].name}`);
        }
      });

  useEffect(() => {
    const onTimeGainLossesData = data[0].stops.map(stop => 0);
    const arrData = data[0].stops.map(stop => 0);
    const depData = data[0].stops.map(stop => 0);

    // For each journey we have
    data.forEach((journey: TrainJourneysModel) => {
      // For each station each journey has
      journey.stops.forEach((station, index: number) => {
        if (station.departureDiff < 60) {
          depData[index] += 1;
        }
        if (station.arrivalDiff < 60) {
          arrData[index] += 1;
        }
      });
    });

    if (dwell) {
      arrData.forEach((v: any, index: number) => {
        if (!index || index === arrData.length - 1) {
          onTimeGainLossesData[index] = 0;
        } else {
          onTimeGainLossesData[index] = depData[index] - v;
        }
      });

      setSeriesData([
        {
          name: "Dwell:",
          data: onTimeGainLossesData.map(value => value)
        }
      ]);
    } else {
      // section

      // there is no section for first station
      onTimeGainLossesData.pop();

      depData.forEach((d: any, index: number) => {
        if (index < arrData.length - 1) {
          onTimeGainLossesData[index] = arrData[index + 1] - depData[index];
        }
      });

      setSeriesData([
        {
          name: "Section:",
          data: onTimeGainLossesData.map(value => value)
        }
      ]);
    }
  }, [data, dwell]);

  const chart = {
    options: {
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 20000,
                color: "#32CD32"
              },
              {
                from: -20000,
                to: 0,
                color: "#DC143C"
              }
            ]
          },
          columnWidth: "65%"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: categories,
        labels: {
          show: true,
          rotate: -90,
          minHeight: labelsHeight,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => val.toFixed(0) + "%"
        }
      },
      markers: {
        size: 4
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
