import { authHeader } from "./../_helpers/auth-header";
import { handleResponse } from "./../_helpers/handle-response";

// models
import StoppingPatternsRequest from "./../models/stopping-patterns-request.model";
import StoppingPatternsResponseModel from "../models/stopping-patterns-response.model";

export default function getStoppingPatterns(params: StoppingPatternsRequest) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  let queryParams = "?";
  for (const [key, value] of Object.entries(params)) {
    queryParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/stopping-patterns${queryParams.slice(
      0,
      -1
    )}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<StoppingPatternsResponseModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}
