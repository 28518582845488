import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";

const DatePickerField = ({
  field,
  form: { setFieldValue, setFieldError, errors },
  ...other
}: {
  field: any;
  form: any;
}) => {
  let currentError = errors[field.name];

  return (
    <KeyboardDatePicker
      name={field.name}
      value={field.value}
      format="dd/MM/yyyy"
      error={Boolean(currentError)}
      onError={error => {
        // handle as a side effect
        if (error && error !== currentError && currentError !== "Required") {
          setFieldError(field.name, error);
          currentError = error;
        }
      }}
      onChange={date => {
        setFieldValue(field.name, date);
      }}
      KeyboardButtonProps={{
        "aria-label": "change date"
      }}
      {...other}
    />
  );
};

export default DatePickerField;
