import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "../../../../models/train-journeys.model";

import { secondsToTime } from "./../../../../_helpers/date-formatter";
import { calculateStandardDeviation } from "./../../../../_helpers/std-calculator";

export default function DwellChart({
  data,
  untilNextLocation,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  untilNextLocation: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  const [seriesLabels, setSeriesLabels] = React.useState<Array<any>>([]);

  const xAxisTitle = untilNextLocation
    ? "Section time Lateness(secs)"
    : "Dwell Lateness (secs)";
  const yAxisTitle = "AVG Lateness (secs)";

  // const getDataLabel = (index: number) => seriesLabels[index];

  useEffect(() => {
    const sectionData = data[0].stops.map(stop => 0);
    const dwellData = data[0].stops.map(stop => 0);
    const sectionDataLabel = data[0].stops.map(stop => "");
    const dwellDataLabel = data[0].stops.map(stop => "");

    // For each journey we have
    let lastStopIndex = data[0].stops.length - 1;

    if (data.length === 1) {
      // Case 1. Only one service
      data.forEach((journey: TrainJourneysModel, journeyIndex: number) => {
        // For each station each journey has
        journey.stops.forEach((station, index: number) => {
          // For dwell skip first and last station
          if (index && index < lastStopIndex) {
            dwellData[index] += station.dwellDiff;
          }

          // For sections skip last station
          if (index < lastStopIndex) {
            sectionData[index] += station.untilNextLocationTimeDiff;
          }

          // Set tooltip
          sectionDataLabel[index] = `<strong>Delay:</strong> ${secondsToTime(
            sectionData[index]
          )} <br/> <strong>Booked:</strong> ${secondsToTime(
            station.untilNextLocationBookedTime
          )} <br/> <strong>Actual:</strong> ${secondsToTime(
            station.untilNextLocationActualTime
          )}`;
          dwellDataLabel[index] = `<strong>Delay:</strong> ${secondsToTime(
            dwellData[index]
          )}  <br/> <strong>Booked:</strong> ${secondsToTime(
            station.dwellBooked
          )} <br/> <strong>Actual:</strong> ${secondsToTime(
            station.dwellActual
          )}`;
        });
      });
    } else if (data.length > 1) {
      // Case 2. Multi services
      // Calculate avg, best, worst
      const sectionBest = data[0].stops.map(stop => 0);
      const sectionWorst = data[0].stops.map(stop => 0);
      const dwellBest = data[0].stops.map(stop => 0);
      const dwellWorst = data[0].stops.map(stop => 0);
      const dataLength = data.length;
      const stdArray = data[0].stops.map(stop => data.map(data => 0));

      data.forEach((journey: TrainJourneysModel, journeyIndex: number) => {
        // For each station each journey has
        journey.stops.forEach((station, index: number) => {
          // For sections skip last station

          stdArray[index][journeyIndex] = station.untilNextLocationTimeDiff;

          if (index < lastStopIndex) {
            sectionData[index] += station.untilNextLocationTimeDiff;

            if (sectionBest[index] >= station.untilNextLocationTimeDiff) {
              sectionBest[index] = station.untilNextLocationTimeDiff;
            }

            if (sectionWorst[index] <= station.untilNextLocationTimeDiff) {
              sectionWorst[index] = station.untilNextLocationTimeDiff;
            }
          }

          // For dwell skip first and last station
          if (index && index < lastStopIndex) {
            dwellData[index] += station.dwellDiff;

            // Best, Worst
            if (dwellBest[index] >= station.dwellDiff) {
              dwellBest[index] = station.dwellDiff;
            }

            if (dwellWorst[index] <= station.dwellDiff) {
              dwellWorst[index] = station.dwellDiff;
            }
          }

          // Avg
          if (journeyIndex === data.length - 1) {
            sectionData[index] = sectionData[index] / dataLength;
            dwellData[index] = dwellData[index] / dataLength;
          }

          // Set tooltip
          sectionDataLabel[
            index
          ] = `<strong>Average lateness:</strong> ${secondsToTime(
            sectionData[index]
          )} <br/> <strong>Best:</strong> ${secondsToTime(
            sectionBest[index]
          )} <br/> <strong>Worst:</strong> ${secondsToTime(sectionWorst[index])}
            <br/> <strong>Standard deviation:</strong> ${secondsToTime(
              calculateStandardDeviation(stdArray[index])
            )}`;
          dwellDataLabel[
            index
          ] = `<strong>Average lateness:</strong> ${secondsToTime(
            dwellData[index]
          )}  <br/> <strong>Best:</strong> ${secondsToTime(
            dwellBest[index]
          )} <br/> <strong>Worst:</strong> ${secondsToTime(dwellWorst[index])}
            <br/> <strong>Standard deviation:</strong> ${secondsToTime(
              calculateStandardDeviation(stdArray[index])
            )}`;
        });
      });
    }

    if (untilNextLocation) {
      setSeriesData([
        {
          name: "Section delay:",
          data: sectionData.map(value => value)
        }
      ]);

      setSeriesLabels(sectionDataLabel);
    } else {
      setSeriesData([
        {
          name: "Dwell delay:",
          data: dwellData.map(value => value)
        }
      ]);

      setSeriesLabels(dwellDataLabel);
    }
  }, [data, untilNextLocation]);

  const chart = {
    options: {
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 20000,
                color: "#DC143C"
              },
              {
                from: -20000,
                to: 0,
                color: "#32CD32"
              }
            ]
          },
          columnWidth: "65%"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: data[0].stops.map(stops => stops.name),
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => val.toFixed(0)
        }
      },
      markers: {
        size: 4
      },
      tooltip: {
        custom: function(dataObject: any) {
          return `<div style="padding:0.5rem;">${
            dataObject.w.config.chartCustomLabels[dataObject.dataPointIndex]
          }</div>`;
        }
      },
      chartCustomLabels: seriesLabels
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
