import React, { useEffect } from "react";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import { green, red, amber, grey } from "@material-ui/core/colors/";

// models
import { SingleStationSearchProject } from "src/models/single-station-search-response.model";

// services
import {
  // dateToLocale,
  // getTime,
  secondsToTime
} from "src/_helpers/date-formatter";
import {
  getComparator,
  sortArrayOfObjects
} from "src/_helpers/sort-array-objects";

interface HeadCell {
  disablePadding: boolean;
  id: any; // id: keyof TrainJourneysModel;
  label: string;
  numeric: boolean;
  align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
}

type Order = "asc" | "desc";

const headCells: HeadCell[] = [
  {
    id: "headCode",
    numeric: false,
    disablePadding: false,
    label: "Head Code",
    align: "left"
  },
  {
    id: "visits",
    numeric: false,
    disablePadding: false,
    label: "Visits in period",
    align: "center"
  },
  {
    id: "avgArrDiff",
    numeric: false,
    disablePadding: false,
    label: "Avg arrival lateness",
    align: "center"
  },
  {
    id: "avgDwellDiff",
    numeric: false,
    disablePadding: false,
    label: "Avg dwell lateness",
    align: "center"
  },
  {
    id: "avgDepDiff",
    numeric: false,
    disablePadding: false,
    label: "Avg departure lateness",
    align: "center"
  }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof SingleStationSearchProject
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof SingleStationSearchProject) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },

    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    cellGreen: {
      backgroundColor: green[600],
      color: "#fff"
    },
    cellAmber: {
      backgroundColor: amber[500]
    },
    cellRed: {
      backgroundColor: red[500],
      color: "#fff"
    },
    cellGrey: {
      backgroundColor: grey[800],
      color: "#fff"
    }
  })
);

interface GroupedSerice {
  headCode: string;
  visits: number;
  avgArrDiff: number;
  avgDepDiff: number;
  avgDwellDiff: number;
}

export default function ServicesTable({
  data,
  filter,
  ...props
}: {
  data: Array<SingleStationSearchProject>;
  filter: any;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [services, setServices] = React.useState<Array<GroupedSerice>>([]);
  const [orderBy, setOrderBy] = React.useState<
    keyof SingleStationSearchProject
  >("headcode");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  useEffect(() => {
    let servicesArray = data;

    if (filter) {
      switch (filter.type) {
        case "headcode":
          servicesArray = servicesArray.filter(
            s => s.headcode.substring(0, 2) === filter.value
          );
          break;
        case "hour":
          servicesArray = servicesArray.filter(
            s => s.stationBookedHour === filter.value
          );
          break;
        default:
          break;
      }
    }

    // group by headcodes
    const groups = servicesArray.reduce(
      (groups: any, item: any) => ({
        ...groups,
        [item.headcode]: [...(groups[item.headcode] || []), item]
      }),
      {}
    );

    // calculate
    let servicesCalculatedArray: any = [];
    Object.entries(groups).forEach(([key, data]: any) => {
      let o: GroupedSerice = {
        headCode: key,
        visits: data.length,
        avgArrDiff: 0,
        avgDepDiff: 0,
        avgDwellDiff: 0
      };

      data.forEach((element: SingleStationSearchProject) => {
        o.avgArrDiff += element.stationArrDiff;
        o.avgDepDiff += element.stationDepDiff;
        o.avgDwellDiff += element.stationDwellDiff;
      });

      o.avgArrDiff /= data.length;
      o.avgDepDiff /= data.length;
      o.avgDwellDiff /= data.length;

      servicesCalculatedArray.push(o);
    });

    setServices(servicesCalculatedArray);
  }, [data, filter]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SingleStationSearchProject
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 20));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={services.length}
            />
            <TableBody>
              {sortArrayOfObjects(services, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: GroupedSerice, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.headCode}>
                      <TableCell id={labelId} scope="row">
                        {row.headCode}
                      </TableCell>
                      <TableCell align="center">{row.visits}</TableCell>
                      <TableCell
                        align="center"
                        className={
                          row.avgArrDiff <= 59
                            ? classes.cellGreen
                            : row.avgArrDiff < 180
                            ? classes.cellAmber
                            : row.avgArrDiff < 60 * 15
                            ? classes.cellRed
                            : row.avgArrDiff > 60 * 15
                            ? classes.cellGrey
                            : ""
                        }
                      >
                        {secondsToTime(row.avgArrDiff)}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={
                          row.avgDwellDiff < 30
                            ? classes.cellGreen
                            : row.avgArrDiff >= 30
                            ? classes.cellAmber
                            : ""
                        }
                      >
                        {secondsToTime(row.avgDwellDiff)}
                      </TableCell>
                      <TableCell align="center">
                        {secondsToTime(row.avgDepDiff)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 25, 50]}
          component="div"
          count={services.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
