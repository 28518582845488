import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "./../../../../models/train-journeys.model";
import { calculateStandardDeviation } from "./../../../../_helpers/std-calculator";

import { secondsToTime } from "./../../../../_helpers/date-formatter";

export default function DelayChart({
  data,
  byArrival,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  byArrival: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  const [seriesLabels, setSeriesLabels] = React.useState<Array<any>>([]);

  const xAxisTitle = byArrival
    ? "Lateness on Station Arrival"
    : "Lateness on Station Departure";
  const yAxisTitle = "AVG Lateness (secs)";

  useEffect(() => {
    const arrData = data[0].stops.map(stop => 0);
    const depData = data[0].stops.map(stop => 0);
    const arrDataLabel = data[0].stops.map(stop => "");
    const depDataLabel = data[0].stops.map(stop => "");
    const arrBest = data[0].stops.map(stop => 0);
    const arrWorst = data[0].stops.map(stop => 0);
    const stdArray = data[0].stops.map(stop => data.map(data => 0));

    // For each journey we have
    data.forEach((journey: TrainJourneysModel, journeyIndex: number) => {
      // For each station each journey has
      journey.stops.forEach((station, index: number) => {
        if (byArrival) {
          if (arrBest[index] >= station.arrivalDiff) {
            arrBest[index] = station.arrivalDiff;
          }

          if (arrWorst[index] <= station.arrivalDiff) {
            arrWorst[index] = station.arrivalDiff;
          }

          stdArray[index][journeyIndex] = station.arrivalDiff;
        } else {
          if (arrBest[index] >= station.departureDiff) {
            arrBest[index] = station.departureDiff;
          }

          if (arrWorst[index] <= station.departureDiff) {
            arrWorst[index] = station.departureDiff;
          }

          stdArray[index][journeyIndex] = station.departureDiff;
        }

        arrData[index] += station.arrivalDiff;
        depData[index] += station.departureDiff;

        if (journeyIndex === data.length - 1) {
          arrData[index] = arrData[index] / data.length;
          depData[index] = depData[index] / data.length;
        }

        // Set tooltip
        arrDataLabel[index] = `
          <strong>Average lateness:</strong>${arrData[index].toFixed(0)}
          <br/> <strong>Count:</strong> ${data.length}
          <br/> <strong>Best:</strong> ${secondsToTime(arrBest[index])}
          <br/> <strong>Worst:</strong> ${secondsToTime(arrWorst[index])}
          <br/> <strong>Standard deviation:</strong> ${secondsToTime(
            calculateStandardDeviation(stdArray[index])
          )}
        `;

        depDataLabel[index] = `
          <strong>Average lateness:</strong>${depData[index].toFixed(0)}
          <br/> <strong>Count:</strong> ${data.length}
          <br/> <strong>Best:</strong> ${secondsToTime(arrBest[index])}
          <br/> <strong>Worst:</strong> ${secondsToTime(arrWorst[index])}
          <br/> <strong>Standard deviation:</strong> ${secondsToTime(
            calculateStandardDeviation(stdArray[index])
          )}
        `;
      });
    });

    if (byArrival) {
      setSeriesData([
        {
          name: "Arrival",
          data: arrData.map(value => value)
        }
      ]);

      setSeriesLabels(arrDataLabel);
    } else {
      setSeriesData([
        {
          name: "Departure",
          data: depData.map(value => value)
        }
      ]);

      setSeriesLabels(depDataLabel);
    }
  }, [data, byArrival]);

  const chart = {
    options: {
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 500,
                color: "#DC143C"
              },
              {
                from: -500,
                to: 0,
                color: "#32CD32"
              }
            ]
          },
          columnWidth: "65%"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: data[0].stops.map(stops => stops.name),
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => val.toFixed(0)
        }
      },
      markers: {
        size: 4
      },
      tooltip: {
        custom: function(dataObject: any) {
          return `<div style="padding:0.5rem;">${
            dataObject.w.config.chartCustomLabels[dataObject.dataPointIndex]
          }</div>`;
        }
      },
      chartCustomLabels: seriesLabels
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
