import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel, {
  JourneyStop
} from "./../../../../models/train-journeys.model";

// services
import { findClosestStopByTimeDifference } from "./../../../../_helpers/find-closest-stop";

export default function StationsStopsChart({
  data,
  byArrival,
  stationName,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  byArrival: boolean; // true for byArrival, false for departure
  stationName: string;
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);

  const seriesTitle = byArrival ? "Arrivals" : "Departures";
  const xAxisTitle = byArrival
    ? "Trains reaching station"
    : "Trains leaving station";
  const yAxisTitle = `No of trains`;

  useEffect(() => {
    const seriesArrayData = new Map<number, number>();
    const seconds: number[] = [
      -30,
      -15,
      -10,
      -5,
      0,
      5,
      10,
      15,
      30,
      60,
      90,
      120,
      3 * 60,
      4 * 60,
      15 * 60,
      15 * 60 + 1
    ];
    seconds.sort((a, b) => (a > b ? 1 : -1));
    seconds.forEach(value => {
      seriesArrayData.set(value, 0);
    });

    if (stationName === "All") {
      data.forEach(value => {
        const closestValue = findClosestStopByTimeDifference(
          seconds,
          value.endDateDifference
        );
        const setValue = seriesArrayData.get(closestValue);
        seriesArrayData.set(closestValue, setValue ? setValue + 1 : 1);
      });
    } else {
      data.forEach(journey => {
        journey.stops.forEach((station: JourneyStop) => {
          if (station.name === stationName) {
            const closestValue = findClosestStopByTimeDifference(
              seconds,
              byArrival ? station.arrivalDiff : station.departureDiff
            );
            const setValue = seriesArrayData.get(closestValue);
            seriesArrayData.set(closestValue, setValue ? setValue + 1 : 1);
          }
        });
      });
    }

    setSeriesData([
      {
        name: seriesTitle,
        data: [...seriesArrayData.values()]
      }
    ]);
  }, [data, byArrival, stationName, seriesTitle]);

  const chart = {
    options: {
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: [
          "-30(s)",
          "-15(s)",
          "-10(s)",
          "-5(s)",
          "0(s)",
          "5(s)",
          "10(s)",
          "15(s)",
          "30(s)",
          "60(s)",
          "90(s)",
          `2(m)`,
          `2-3(m)`,
          `3-4(m)`,
          `5-15(m)`,
          `15+(m)`
        ],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        }
      },
      markers: {
        size: 4
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
