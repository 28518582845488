import React from "react";

// material
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

// models
import ServicesResponseModel from "src/models/services-response.model";

import { authorisationService } from "src/services/authorisation.service";

// components

import PercentilesChart from "./percentiles.chart";
import HeatmapChart from "./heatmap.chart";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "850px"
  }
}));

export default function ChartTabs({
  data,
  ...props
}: {
  data: ServicesResponseModel;
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let tabIndex = {
    tabOne: 0,
    tabTwo: 1,
    tabThree: 1,
    tabFour: 2,
    tabFive: 3,
    tabSix: 4
  };

  if (authorisationService.hasModeratorAccess()) {
    tabIndex = {
      tabOne: 0,
      tabTwo: 1,
      tabThree: 2,
      tabFour: 3,
      tabFive: 4,
      tabSix: 5
    };
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Chart tabs"
          variant="standard"
        >
          <Tab label="Percentiles" {...a11yProps(tabIndex.tabOne)} />
          {/* <Tab label="Time lost vs On Time %" {...a11yProps(tabIndex.tabTwo)} /> */}
          <Tab label="Heat map" {...a11yProps(tabIndex.tabTwo)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={tabIndex.tabOne}>
        <Paper>
          <Box p={1}>
            <PercentilesChart data={data} />
          </Box>
        </Paper>
      </TabPanel>
      {/* <TabPanel value={value} index={tabIndex.tabTwo}>
        <Paper>
          <Box p={1}>
          <OnTimeChart data={data} />
          </Box>
        </Paper>
      </TabPanel> */}
      <TabPanel value={value} index={tabIndex.tabTwo}>
        <Paper>
          <Box p={1}>
            <HeatmapChart data={data["period-A-services"]} period={1} />
          </Box>
        </Paper>
        <Paper>
          <Box p={1}>
            <HeatmapChart data={data["period-B-services"]} period={2} />
          </Box>
        </Paper>
      </TabPanel>

      {/* <TabPanel value={value} index={tabIndex.tabThree}>
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Box p={1}>
                <OnTimeFlatChart data={chartSelectedData} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box p={1}>
                <OnTimeGainsLossesChart data={chartSelectedData} dwell={true} />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <OnTimeChart data={chartSelectedData} bySeconds={false} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OnTimeGainsLossesChart data={chartSelectedData} dwell={false} />
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Box mt={2} p={1}>
            <OnTimeChart data={chartSelectedData} bySeconds={true} />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabFour}>
        <Paper>
          <Box p={1}>
            <StationStopsChart
              data={chartSelectedData}
              byArrival={true}
              stationName={selectedStation}
            />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <StationStopsChart
              data={chartSelectedData}
              byArrival={false}
              stationName={selectedStation}
            />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabFive}>
        <Paper>
          <Box p={1}>
            <DelayChart data={chartSelectedData} byArrival={false} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <DelayChart data={chartSelectedData} byArrival={true} />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabSix}>
        <Paper>
          <Box p={1}>
            <DwellChart data={chartSelectedData} untilNextLocation={false} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <DwellChart data={chartSelectedData} untilNextLocation={true} />
          </Box>
        </Paper>
      </TabPanel> */}
    </div>
  );
}
