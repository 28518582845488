import { authHeader } from "./../_helpers/auth-header";
import { handleResponse } from "./../_helpers/handle-response";

// models
import JourneysListModel from "./../models/journey-list.model";
import StopPatternsInModel from "./../models/stop-patterns-in.model";
import StopPatternsResponseModel from "../models/stop-patterns-response.model";
import TrainJourneysModel from "./../models/train-journeys.model";

export const trainJourneysService = {
  getStopPatterns,
  getJourneysList
};

function getStopPatterns(params: StopPatternsInModel) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  const queryParams = Object.values(params)
    .map(value => encodeURIComponent(value))
    .join("/");

  return fetch(
    `${process.env.REACT_APP_API_URL}/TrainJourneys/${queryParams}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<StopPatternsResponseModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}

function getJourneysList(params: JourneysListModel) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  const queryParams = Object.values(params)
    .map(value => encodeURIComponent(value))
    .join("/");

  return fetch(
    `${process.env.REACT_APP_API_URL}/TrainJourneys/${queryParams}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<TrainJourneysModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}
