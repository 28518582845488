import React from "react";

// material
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const CheckBoxFieldWithLabel = ({
  field,
  form,
  label,
  isChecked,
  ...props
}: {
  field: any;
  form: any;
  label: string;
  isChecked: boolean;
}) => {
  // const currentError = form.errors[field.name];

  return (
    <FormControlLabel
      control={
        <Checkbox
          {...field}
          {...props}
          value={field.value}
          onChange={event =>
            form.setFieldValue(field.name, event.target.checked, false)
          }
          checked={field.value || isChecked || false}
        />
      }
      label={label}
    />
  );
};

export default CheckBoxFieldWithLabel;
