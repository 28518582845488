import React, { useEffect } from "react";
import clsx from "clsx";
import { CSVLink } from "react-csv";

// material
import {
  createStyles,
  lighten,
  makeStyles,
  Theme
} from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import TrainIcon from "@material-ui/icons/Train";
import Typography from "@material-ui/core/Typography";

// models
import TrainJourneysModel from "./../../../models/train-journeys.model";

// services
import {
  dateToLocale,
  getTime,
  secondsToTime
} from "./../../../_helpers/date-formatter";
import {
  getComparator,
  sortArrayOfObjects
} from "./../../../_helpers/sort-array-objects";

// components
import FaultsDialog from "./faults-dialog";
import JourneyDialog from "./journey-dialog";
import ChartTabs from "./charts/chart-tabs";

interface HeadCell {
  disablePadding: boolean;
  id: any; // id: keyof TrainJourneysModel;
  label: string;
  numeric: boolean;
  align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
}

type Order = "asc" | "desc";

const headCells: HeadCell[] = [
  {
    id: "headcode",
    numeric: false,
    disablePadding: false,
    label: "Head Code",
    align: "left"
  },
  {
    id: "unitNumber",
    numeric: false,
    disablePadding: false,
    label: "Unit number",
    align: "left"
  },
  {
    id: "bookedStartDate",
    numeric: false,
    disablePadding: false,
    label: "Start date",
    align: "left"
  },
  {
    id: "actualStartDate",
    numeric: false,
    disablePadding: false,
    label: "Actual start time",
    align: "left"
  },
  {
    id: "bookedEndDate",
    numeric: false,
    disablePadding: false,
    label: "End time",
    align: "left"
  },
  {
    id: "actualEndDate",
    numeric: false,
    disablePadding: false,
    label: "Actual end time",
    align: "left"
  },
  {
    id: "criticalSystems",
    numeric: false,
    disablePadding: false,
    label: "Faults",
    align: "center"
  },
  {
    id: "isDelayed",
    numeric: false,
    disablePadding: false,
    label: "Delayed",
    align: "center"
  },
  {
    id: "startDateDifference",
    numeric: false,
    disablePadding: false,
    label: "Delayed time",
    align: "center"
  },
  {
    id: "journeyTime",
    numeric: false,
    disablePadding: false,
    label: "Journey time",
    align: "center"
  },
  {
    id: "viewDetails",
    numeric: false,
    disablePadding: true,
    label: "Details",
    align: "center"
  }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TrainJourneysModel
  ) => void;
  onSelectAllClick: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  order: Order;
  orderBy: string;
  rowsPerPage: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowsPerPage,
    onRequestSort
  } = props;
  const createSortHandler = (property: keyof TrainJourneysModel) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowsPerPage}
            checked={rowsPerPage > 0 && numSelected === rowsPerPage}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            className={`${classes.headCell} ${classes.tableCell}`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: "1 1 100%"
    }
  })
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Route results
        </Typography>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750,
      height: "auto"
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    headCell: {
      lineHeight: "1.2rem"
    },
    tableCell: {
      padding: theme.spacing(1)
    }
  })
);

export default function ResultsTable({
  data,
  details,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  details: any;
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof TrainJourneysModel>(
    "bookedStartDate"
  );
  const [selected, setSelected] = React.useState<any[]>([]);
  const [selectedData, setSelectedData] = React.useState<any[]>(data);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const csvHeaders = headCells
    .map(header => {
      return { label: header.label, key: header.id };
    })
    .concat(
      [
        {
          id: "name",
          label: "Station stop"
        },
        {
          id: "bookedArrival",
          label: "Booked Arrival"
        },
        {
          id: "actualArrival",
          label: "Actual"
        },
        {
          id: "bookedDeparture",
          label: "Booked Departure"
        },
        {
          id: "actualDeparture",
          label: "Actual"
        },
        {
          id: "untilNextLocationBookedTime",
          label: "Booked until next station"
        },
        {
          id: "untilNextLocationActualTime",
          label: "Actual"
        },
        {
          id: "dwellBooked",
          label: "Booked Dwell time"
        },
        {
          id: "dwellActual",
          label: "Actual"
        }
      ].map(stopsHeader => {
        return { label: stopsHeader.label, key: stopsHeader.id };
      })
    );

  // remove details and criticalSystems
  csvHeaders.splice(6, 1);
  csvHeaders.splice(9, 1);

  const csvData: Array<any> = [];
  data.forEach(d => {
    d.stops.forEach((s: any) => {
      csvData.push({ ...d, ...s });
    });
  });

  useEffect(() => {
    if (selected.length) {
      const selectedDataArray: Array<any> = [];
      sortArrayOfObjects(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .forEach(row => {
          if (
            selected.find(
              s =>
                s === `${row.headcode}-${row.unitNumber}-${row.bookedStartDate}`
            )
          ) {
            selectedDataArray.push(row);
          }
        });
      setSelectedData(selectedDataArray);
    } else {
      setSelectedData(data);
    }
  }, [selected, data, rowsPerPage, page, order, orderBy]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TrainJourneysModel
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, pattern: string) => {
    const selectedIndex = selected.indexOf(pattern);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, pattern);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    setSelectedData(data);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);

    // Clear selected rows
    setSelected([]);
    setSelectedData(data);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const selected = sortArrayOfObjects(data, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(
          (row, index) =>
            `${row.headcode}-${row.unitNumber}-${row.bookedStartDate}`
        );
      setSelected(selected);
      return;
    }
    setSelected([]);
    setSelectedData(data);
  };

  const isSelected = (pattern: string) => selected.indexOf(pattern) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <Box display="flex" justifyContent="flex-end" m={1} p={1}>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={`${details.routeName}-${details.startDate}-${details.endDate}`}
            className="btn btn-primary"
            target="_blank"
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<GetAppIcon />}
            >
              Download
            </Button>
          </CSVLink>
        </Box>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
          component="div"
          id="pagination"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowsPerPage={rowsPerPage}
            />
            <TableBody>
              {sortArrayOfObjects(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const uniqueIdentifier = `${row.headcode}-${row.unitNumber}-${row.bookedStartDate}`;
                  const isItemSelected = isSelected(uniqueIdentifier);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={event =>
                            handleClick(event, uniqueIdentifier)
                          }
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.headcode}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {row.unitNumber}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {dateToLocale(row.bookedStartDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getTime(row.actualStartDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getTime(row.bookedEndDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {getTime(row.actualEndDate)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <FaultsDialog criticalSystems={row.criticalSystems} />
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {row.isDelayed > 0 ? (
                          <TrainIcon style={{ color: red[500] }} />
                        ) : (
                          <TrainIcon style={{ color: green[500] }} />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.endDateDifference)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.journeyTime)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        <JourneyDialog stops={row.stops} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 57 * emptyRows }}>
                  <TableCell colSpan={12} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 250]}
          component="div"
          id="pagination"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>

      {/* add tabs */}
      <ChartTabs data={data} selectedData={selectedData} />
    </div>
  );
}
