export function dateToLocale(date: string | Date) {
  return new Date(date).toLocaleString("en-GB", { timeZone: "UTC" });
}

export function getTime(dateTime: string | Date) {
  return new Date(dateTime).toLocaleTimeString("en-GB", { timeZone: "UTC" });
}

export function secondsToTime(seconds: number) {
  if (seconds > 0) {
    return new Date(seconds * 1000).toISOString().substr(11, 8);
  }
  return "-" + new Date(-seconds * 1000).toISOString().substr(11, 8);
}

export function getDay(dateTime: string | Date) {
  return new Date(dateTime).getDay();
}

export function getDayName(dateTime: string | Date): string {
  return new Intl.DateTimeFormat("en-UK", { weekday: "long" }).format(
    new Date(dateTime)
  );
}
