import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "./../../../../models/train-journeys.model";

export default function OnTimeChart({
  data,
  bySeconds,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  bySeconds: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  const [yMax, setYMax] = React.useState(0);

  const xAxisTitle = "On Time";
  const yAxisTitle = bySeconds ? "Lateness (secs)" : "Trains on time (%)";
  const categories: Array<string> = [];

  data[0].stops.forEach((stop, index) => {
    if (!index) {
      categories.push(`${stop.name} O`);
    } else if (index === data[0].stops.length - 1) {
      categories.push(`${stop.name} T`);
    } else {
      categories.push(`${stop.name} A`);
      categories.push(`${stop.name} D`);
    }
  });

  useEffect(() => {
    const arrFlatData = data[0].stops
      .map(stop => 0)
      .concat(data[0].stops.map(stop => 0));

    const arrData = data[0].stops.map(stop => 0);
    const depData = data[0].stops.map(stop => 0);
    let ymax = 0;

    // For each journey we have
    data.forEach((journey: TrainJourneysModel) => {
      // For each station each journey has
      journey.stops.forEach((station, index: number) => {
        if (!bySeconds) {
          // Check if delay in actualArrival or actualDeparture of more than 1 min
          if (station.departureDiff < 60) {
            depData[index] += 1;
          }
          if (station.arrivalDiff < 60) {
            arrData[index] += 1;
          }
        } else {
          arrData[index] += station.arrivalDiff;
          depData[index] += station.departureDiff;
        }
      });
    });

    arrFlatData.forEach((v: any, index: number) => {
      if (!index) {
        arrFlatData[index] = depData[index];
      } else if (index % 2) {
        arrFlatData[index] = arrData[(index + 1) / 2];
      } else {
        arrFlatData[index] = depData[index / 2];
      }

      if (arrFlatData[index] > ymax) {
        ymax = arrFlatData[index];
        setYMax(arrFlatData[index] / data.length);
      }
    });
    // We need one station for start and end
    arrFlatData.splice(arrFlatData.length - 2, 2);

    if (bySeconds) {
      setSeriesData([
        {
          name: "Lateness",
          data: arrFlatData.map(value => value / data.length)
        }
      ]);
    } else {
      setSeriesData([
        {
          name: "On Time",
          data: arrFlatData.map(value => {
            return value ? (100 * value) / data.length : null;
          })
        }
      ]);
    }
  }, [data, bySeconds]);

  const chart = {
    options: {
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: categories,
        labels: {
          show: true,
          rotate: -90,
          minHeight: 150,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => {
            if (bySeconds) {
              return val !== null ? val.toFixed(0) + "s" : "";
            } else {
              return val !== null && val >= 0 ? val.toFixed(0) + "%" : "";
            }
          }
        },
        max: bySeconds ? yMax : 100
      },
      markers: {
        size: 4
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="line"
      height="400"
    />
  );
}
