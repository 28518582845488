import React from "react";

// material
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

// models
import SingleStationSearchResponse from "src/models/single-station-search-response.model";

// components
import LatenessDistributionOfServicesChart from "./lateness-distribution-of-services";
import OnTimePerformanceByHeadCodeChart from "./onTime-performance-by-headcode";
import OnTimePerformanceVsTimeOfDay from "./onTime-performance-vs-time-of-day";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "850px"
  }
}));

export default function ChartTabs({
  data,
  passTableFilter,
  ...props
}: {
  data: SingleStationSearchResponse;
  passTableFilter: any;
}) {
  const classes = useStyles();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  let tabIndex = {
    tabOne: 0,
    tabTwo: 1,
    tabThree: 2,
    tabFour: 3,
    tabFive: 4,
    tabSix: 5
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Chart tabs"
          variant="standard"
        >
          <Tab label="Lateness Distribution" {...a11yProps(tabIndex.tabOne)} />
          <Tab label="On Time % by HeadCode" {...a11yProps(tabIndex.tabTwo)} />
          <Tab label="On Time % by Hour" {...a11yProps(tabIndex.tabThree)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={tabIndex.tabOne}>
        <Paper>
          <Box p={1}>
            <LatenessDistributionOfServicesChart data={data.services} />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabTwo}>
        <Paper>
          <Box p={1}>
            <OnTimePerformanceByHeadCodeChart
              data={data.singleStationSearchHeadcodeGrouping}
              passTableFilter={passTableFilter}
            />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabThree}>
        <Paper>
          <Box p={1}>
            <OnTimePerformanceVsTimeOfDay
              data={data.singleStationSearchHourGrouping}
              passTableFilter={passTableFilter}
            />
          </Box>
        </Paper>
      </TabPanel>
    </div>
  );
}
