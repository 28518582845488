import { PeakFilters } from "./peak-filters.enum";

export const peakStrings: {
  name: string;
  time: string;
  value: PeakFilters;
}[] = [
  {
    value: PeakFilters.OffPeakMorning,
    name: "Off-Peak Morning",
    time: "00:00:00 - 07:00:00"
  },
  {
    value: PeakFilters.OffPeakAfternoon,
    name: "Off-Peak Afternoon",
    time: "10:00:00 - 16:00:00"
  },
  {
    value: PeakFilters.OffPeakEvening,
    name: "Off-Peak Evening",
    time: "19:00:00 - 23:59:59"
  },
  {
    value: PeakFilters.PeakMorning,
    name: "Peak Morning",
    time: "07:00:00 - 10:00:00"
  },
  {
    value: PeakFilters.PeakAfternoon,
    name: "Peak Afternoon",
    time: "16:00:00 - 19:00:00"
  },
  {
    value: PeakFilters.All,
    name: "All",
    time: "All"
  }
];
