import React from "react";

// material
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";

// models
import { Range, Order } from "./../../../models/shared";

// components
import DwellRelatedStatisticsChart from "./dwell-related-statistics.chart";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "850px"
  },
  chartHeader: {
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  rangeTransform: {
    textTransform: "capitalize"
  }
}));

export default function ChartsView({ ...props }) {
  const classes = useStyles();

  const [range, setRange] = React.useState<Range>(Range.weekly);
  const [rangeLabel, setRangeLabel] = React.useState("week");

  const handleChange = (event: any) => {
    setRange(event.target.value);

    event.target.value === Range.weekly
      ? setRangeLabel("week")
      : setRangeLabel("month");
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">View range</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={range}
            className={classes.rangeTransform}
            onChange={handleChange}
          >
            <MenuItem value={Range.weekly} className={classes.rangeTransform}>
              {Range.weekly}
            </MenuItem>
            <MenuItem value={Range.monthly} className={classes.rangeTransform}>
              {Range.monthly}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Paper>
        <Box p={1}>
          <h2 className={classes.chartHeader}>
            Last {rangeLabel}'s best stations
          </h2>
          <DwellRelatedStatisticsChart order={Order.asc} range={range} />
        </Box>
      </Paper>
      <Paper>
        <Box mt={3} p={1}>
          <h2 className={classes.chartHeader}>
            Last {rangeLabel}'s worst stations
          </h2>
          <DwellRelatedStatisticsChart order={Order.desc} range={range} />
        </Box>
      </Paper>
    </div>
  );
}
