import React from "react";
import clsx from "clsx";

import {
  BrowserRouter as Router,
  NavLink as RouterLink,
  Route,
  Switch,
  useRouteMatch
} from "react-router-dom";

// material
import { makeStyles } from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import CloseIcon from "@material-ui/icons/Close";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Slide from "@material-ui/core/Slide";
import TimelineIcon from "@material-ui/icons/Timeline";
import Toolbar from "@material-ui/core/Toolbar";
import TrainIcon from "@material-ui/icons/Train";
import Typography from "@material-ui/core/Typography";

// assets
import styles from "./dashboard.module.css";
import { ReactComponent as Logo } from "./southeastern-logo-light.svg";

// components
import AmberView from "./amber-table/amber-view";
import ChartsView from "./charts/charts-view";
import CompareServices from "./../compare-services/compare-services";
import Copyright from "../shared/copyright/copyright";
import StationDashboard from "./../station-dashboard/station-dashboard";
import Statistics from "./../statistics/statistics";
import MappingTool from "./../mapping-tool/mapping-tool";

// services
import { authenticationService } from "src/services/authentication.service";
// import { authorisationService } from "src/services/authorisation.service";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  toolbar: {
    paddingRight: 24 // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  title: {
    flexGrow: 1
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column"
  },
  fixedHeight: {
    height: 240
  },
  dialogAppBar: {
    position: "relative"
  },
  dialogTitle: {
    flexGrow: 1
  },
  active: {
    backgroundColor: "#bbdefb"
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Dashboard() {
  const classes = useStyles();
  let { path, url } = useRouteMatch();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    console.log(openDialog);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Logo className={styles.logo} />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            {" "}
            Pulse
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={menuOpen}
              onClose={handleClose}
            >
              <MenuItem onClick={authenticationService.logout}>Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Router>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <div>
              <ListItem
                exact={true}
                activeClassName={classes.active}
                button
                component={RouterLink}
                to="/dashboard"
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
              <ListItem
                activeClassName={classes.active}
                button
                component={RouterLink}
                to={`${url}/statistics`}
              >
                <ListItemIcon>
                  <TimelineIcon />
                </ListItemIcon>
                <ListItemText primary="Service statistics" />
              </ListItem>
              {/* {authorisationService.hasModeratorAccess() && ( */}
              <ListItem
                activeClassName={classes.active}
                button
                component={RouterLink}
                to={`${url}/compare-services`}
              >
                <ListItemIcon>
                  <CompareArrowsIcon />
                </ListItemIcon>
                <ListItemText primary="Compare services" />
              </ListItem>
              <ListItem
                activeClassName={classes.active}
                button
                component={RouterLink}
                to={`${url}/station-dashboard`}
              >
                <ListItemIcon>
                  <TrainIcon />
                </ListItemIcon>
                <ListItemText primary="Station dashboard" />
              </ListItem>

              <ListItem
                button
                // component={RouterLink}
                // to={`${url}/mapping-tool`}
                onClick={handleClickOpenDialog}
              >
                <ListItemIcon>
                  <TrainIcon />
                </ListItemIcon>
                <ListItemText primary="Mapping Tool" />
              </ListItem>
              {/* )} */}
              {/* <ListItem activeClassName={classes.active} button component={RouterLink} to={`${url}/users`}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Manage users" />
              </ListItem>
              <ListItem activeClassName={classes.active} button component={RouterLink} to={`${url}/my-account`}>
                <ListItemIcon>
                  <AccountBoxIcon />
                </ListItemIcon>
                <ListItemText primary="My account" />
              </ListItem> */}
            </div>
          </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Switch>
              <Route exact path={path}>
                <ChartsView />
                <Box mt={3}>
                  <AmberView />
                </Box>
              </Route>
              <Route path={`${path}/statistics`}>
                <Statistics />
              </Route>
              <Route path={`${path}/compare-services`}>
                <CompareServices />
              </Route>
              {/* {authorisationService.hasModeratorAccess() && ( */}
              <Route path={`${path}/station-dashboard/:station/:range`}>
                <StationDashboard />
              </Route>
              <Route path={`${path}/station-dashboard`}>
                <StationDashboard />
              </Route>
              {/* )} */}
              {/* <Route path={`${path}/mapping-tool`}>
                <MappingTool />
              </Route> */}
            </Switch>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </Router>

      <Dialog fullScreen open={openDialog} TransitionComponent={Transition}>
        <AppBar className={classes.dialogAppBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.dialogTitle}>
              Mapping Tool
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleCloseDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MappingTool />
      </Dialog>
    </div>
  );
}
