import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

// models
import ServicesResponseModel, {
  ServiceJourneysModel
} from "src/models/services-response.model";
import ServicesRequest from "src/models/services-request.model";

import { getTime, getDay } from "src/_helpers/date-formatter";

export default function HeatmapChart({
  data,
  period,
  ...props
}: {
  data: ServicesResponseModel["period-A-services"];
  period: number;
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);

  const xAxisTitle = "Hours of day";
  const yAxisTitle = "Days of week";
  const hoursOfDay = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23"
  ];
  const params: ServicesRequest = useParams<any>();
  const chartTitle =
    period === 1
      ? `${params.start_date} - ${params.end_date}`
      : `${params.start_date_to_compare} - ${params.end_date_to_compare}`;

  useEffect(() => {
    const daysArray = [...Array(7)].map(
      day => (day = [...Array(24)].map(v => 0))
    );
    const daysArrayCounter = [...Array(7)].map(
      day => (day = [...Array(24)].map(v => 0))
    );

    // For each journey we have
    data.forEach((journey: ServiceJourneysModel) => {
      // For each station each journey has
      journey.stops.forEach((station, index: number) => {
        let timeOfDay = parseInt(getTime(station.bookedArrival).slice(0, 2));
        let day = getDay(station.bookedArrival);

        if (!station.delayed) {
          daysArray[day][timeOfDay] += 1;
        }

        daysArrayCounter[day][timeOfDay] += 1;
      });
    });

    setSeriesData([
      {
        name: "S",
        data: daysArray[0].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[0][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "M",
        data: daysArray[1].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[1][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "T",
        data: daysArray[2].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[2][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "W",
        data: daysArray[3].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[3][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "T",
        data: daysArray[4].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[4][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "F",
        data: daysArray[5].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[5][index]).toFixed(0)
            : 0;
        })
      },
      {
        name: "S",
        data: daysArray[6].map((value, index) => {
          return value
            ? ((100 * value) / daysArrayCounter[6][index]).toFixed(0)
            : 0;
        })
      }
    ]);
  }, [data]);

  const chart = {
    options: {
      dataLabels: {
        enabled: true,
        formatter: (val: number) => val.toFixed(0) + "%"
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: hoursOfDay,
        title: {
          text: "On time"
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        }
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0,
          radius: 0,
          useFillColorAsStroke: true,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                name: "No data",
                color: "#ffffff"
              },
              {
                from: 1,
                to: 64,
                name: "0-64%",
                color: "#f44336"
              },
              {
                from: 65,
                to: 74,
                name: "66%-74%",
                color: "#ffc107"
              },
              {
                from: 75,
                to: 100,
                name: ">75%",
                color: "#1b5e20"
              }
            ]
          }
        }
      },
      stroke: {
        width: 1
      },
      title: {
        text: chartTitle
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="heatmap"
      height="350"
    />
  );
}
