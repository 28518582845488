import { authHeader } from "./../_helpers/auth-header";
import { handleResponse } from "./../_helpers/handle-response";

// models
import ServicesRequest from "./../models/services-request.model";
import ServicesResponseModel from "../models/services-response.model";

export default function getServices(params: ServicesRequest) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  let queryParams = "?";
  for (const [key, value] of Object.entries(params)) {
    queryParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/services/${queryParams.slice(0, -1)}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<ServicesResponseModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}
