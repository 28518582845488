import React, { useEffect } from "react";

import { useParams } from "react-router-dom";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

// models
import { peakStrings } from "./../../../models/peak-filters-values";
import JourneysListModel from "./../../../models/journey-list.model";
import TrainJourneysModel from "./../../../models/train-journeys.model";

// services
import { trainJourneysService } from "./../../../services/train-journeys.service";

// components
import ResultsTable from "./results-table";

const detailsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: { minWidth: "100px" }
  })
);
const progressStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative"
    },
    progress: {
      position: "absolute",
      top: "50%",
      marginTop: -12,
      marginLeft: theme.spacing(16)
    }
  })
);

export default function RoutesTable() {
  const listClasses = detailsListStyles();
  const progressClasses = progressStyles();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [trainJourneys, setTrainJourneys] = React.useState<
    Array<TrainJourneysModel>
  >();

  const params: JourneysListModel = useParams<{
    routeName: string;
    startDate: string;
    endDate: string;
    peakTime: string;
    headCode: string;
    days: string;
  }>();

  useEffect(() => {
    setError(null);
    setLoading(true);
    trainJourneysService
      .getJourneysList(params)
      .then((res: Array<TrainJourneysModel> | any) => {
        setLoading(false);

        if (res.error) {
          setError(res.error);
        } else {
          setTrainJourneys(res);
        }
      });
  }, [params]);

  return (
    <div>
      {error && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          An error has occurred. Please try again, and if the problem persists,
          contact your help desk or system administrator.{" "}
          <p>
            {" "}
            (<strong>{error}</strong>)
          </p>
        </Alert>
      )}
      <h1>Route</h1>
      <List>
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Stop pattern:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.routeName} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Start date:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.startDate} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>End date:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.endDate} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Peak time:</strong>
          </ListItemAvatar>
          <ListItemText
            secondary={
              peakStrings.find(p => p.value === Number(params.peakTime))?.name
            }
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Headcode:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.headCode} />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemAvatar className={listClasses.avatar}>
            <strong>Days:</strong>
          </ListItemAvatar>
          <ListItemText secondary={params.days} />
        </ListItem>
      </List>

      {loading && (
        <Box mt={3}>
          <Alert severity="info" className={progressClasses.wrapper}>
            Loading results ...{" "}
            <CircularProgress size={24} className={progressClasses.progress} />
          </Alert>
        </Box>
      )}

      {trainJourneys && trainJourneys.length > 0 && params && (
        <Box mt={3}>
          <ResultsTable data={trainJourneys} details={params} />
        </Box>
      )}
    </div>
  );
}
