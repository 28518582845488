import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import { SingleStationSearchHourGrouping } from "src/models/single-station-search-response.model";

export default function OnTimePerformanceVsTimeOfDay({
  data,
  passTableFilter,
  ...props
}: {
  data: Array<SingleStationSearchHourGrouping>;
  passTableFilter: any;
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);

  const xAxisTitle = "Time of the day";
  const yAxisTitle = "On Time";

  useEffect(() => {
    const series = data.sort((a, b) => {
      if (a.hour < b.hour) {
        return -1;
      }
      if (a.hour > b.hour) {
        return 1;
      }
      return 0;
    });

    setSeriesData([
      {
        name: "On time",
        data: series.map(service => service.onTimeScore)
      }
    ]);
  }, [data]);

  const chart = {
    options: {
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle,
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            passTableFilter({
              type: "hour",
              value: data[config.dataPointIndex].hour
            });
          }
        }
      },
      xaxis: {
        type: "category" as "category",
        categories: data
          .map(service => service.hour)
          .sort((a, b) => {
            if (a < b) {
              return -1;
            }
            if (a > b) {
              return 1;
            }
            return 0;
          }),
        labels: {
          show: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => val.toFixed(0) + "%"
        },
        max: 100
      },
      markers: {
        size: 4
      },
      tooltip: {
        custom: function(service: any) {
          return `<div style="padding:0.5rem;">${
            data[service.dataPointIndex].tCount
          } trains</div>`;
        }
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
