import React from "react";

// material
import TextField from "@material-ui/core/TextField";
import { getIn } from "formik";

const MaterialInputField = ({
  field,
  form: { touched, errors, dirty },
  pattern,
  ...props
}: {
  field: any;
  form: any;
  pattern: string;
}) => {
  const errorText = getIn(errors, field.name);
  const touchedVal = getIn(touched, field.name);
  const hasError = dirty && touchedVal && errorText !== undefined;

  return (
    <TextField
      {...field}
      {...props}
      value={field.value}
      inputProps={{ pattern: pattern }}
      error={hasError}
      helperText={hasError ? errorText : ""}
    />
  );
};

export default MaterialInputField;
