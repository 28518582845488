import React, { useEffect } from "react";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";

// models
import TrainJourneysModel from "./../../../../models/train-journeys.model";

// services
import {
  getComparator,
  sortArrayOfObjects
} from "./../../../../_helpers/sort-array-objects";

import { authorisationService } from "src/services/authorisation.service";

// components
import DelayChart from "./delay.chart";
import DwellChart from "./dwell.chart";
import OnTimeFlatChart from "./on-time-flat.chart";
import OnTimeGainsLossesChart from "./on-time-gains-losses.chart";
import PercentilesChart from "./percentiles.chart";
import RTLatencyChart from "./rt-latency.chart";
import StationStopsChart from "./stations-stops.chart";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "850px"
  }
}));

const buttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      boxShadow: "none",
      backgroundColor: green[500],
      borderColor: green[500],
      color: "#fff",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: green[500],
        borderColor: green[500],
        color: "#fff"
      }
    }
  })
);

export default function ChartTabs({
  data,
  selectedData,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  selectedData: Array<TrainJourneysModel>;
}) {
  const classes = useStyles();
  const buttonClasses = buttonStyles();
  const stationButtons = [...data[0].stops.map(s => s.name), "All"];

  const [value, setValue] = React.useState(0);
  const [chartData, setChartData] = React.useState<Array<any>>(data);
  const [chartSelectedData, setSelectedChartData] = React.useState<Array<any>>(
    data
  );
  const [selectedStation, setSelectedStation] = React.useState<string>(`All`);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (event: React.MouseEvent<unknown>, station: string) => {
    setSelectedStation(station);
  };

  useEffect(() => {
    setChartData(
      sortArrayOfObjects(data, getComparator("asc", "endDateDifference"))
    );
    setSelectedChartData(
      sortArrayOfObjects(
        selectedData,
        getComparator("asc", "endDateDifference")
      )
    );
  }, [data, selectedData]);

  let tabIndex = {
    tabOne: 0,
    tabTwo: 1,
    tabThree: 1,
    tabFour: 2,
    tabFive: 3,
    tabSix: 4
  };

  if (authorisationService.hasModeratorAccess()) {
    tabIndex = {
      tabOne: 0,
      tabTwo: 1,
      tabThree: 2,
      tabFour: 3,
      tabFive: 4,
      tabSix: 5
    };
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Chart tabs"
          variant="standard"
        >
          <Tab label="Percentiles" {...a11yProps(tabIndex.tabOne)} />
          {authorisationService.hasModeratorAccess() && (
            <Tab label="RT & Lateness" {...a11yProps(tabIndex.tabTwo)} />
          )}
          <Tab label="On Time & Lateness" {...a11yProps(tabIndex.tabThree)} />
          <Tab label="Station Stops" {...a11yProps(tabIndex.tabFour)} />
          <Tab label="Lateness per station" {...a11yProps(tabIndex.tabFive)} />
          <Tab
            label="Dwells & Section Delays"
            {...a11yProps(tabIndex.tabSix)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={tabIndex.tabOne}>
        <Paper>
          <Box p={1}>
            <PercentilesChart data={chartData} outgoing={false} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <PercentilesChart data={chartData} outgoing={true} />
          </Box>
        </Paper>
      </TabPanel>
      {authorisationService.hasModeratorAccess() && (
        <TabPanel value={value} index={tabIndex.tabTwo}>
          <>
            <Paper>
              <Box p={1}>
                <RTLatencyChart data={chartSelectedData} bySeconds={false} />
              </Box>
            </Paper>
            <Paper>
              <Box mt={2} p={1}>
                <RTLatencyChart data={chartSelectedData} bySeconds={true} />
              </Box>
            </Paper>
          </>
        </TabPanel>
      )}
      <TabPanel value={value} index={tabIndex.tabThree}>
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <Box p={1}>
                <OnTimeFlatChart data={chartSelectedData} bySeconds={false} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box p={1}>
                <OnTimeGainsLossesChart data={chartSelectedData} dwell={true} />
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={8}>
              <OnTimeFlatChart data={chartSelectedData} bySeconds={true} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <OnTimeGainsLossesChart data={chartSelectedData} dwell={false} />
            </Grid>
          </Grid>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabFour}>
        <Box m={2}>
          {stationButtons.map((station, idx) => {
            return (
              <Button
                className={`${
                  selectedStation === station ? buttonClasses.active : ""
                }`}
                variant="outlined"
                key={idx}
                onClick={event => handleClick(event, station)}
              >
                {station}
              </Button>
            );
          })}
        </Box>
        <Paper>
          <Box p={1}>
            <StationStopsChart
              data={chartSelectedData}
              byArrival={true}
              stationName={selectedStation}
            />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <StationStopsChart
              data={chartSelectedData}
              byArrival={false}
              stationName={selectedStation}
            />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabFive}>
        <Paper>
          <Box p={1}>
            <DelayChart data={chartSelectedData} byArrival={false} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <DelayChart data={chartSelectedData} byArrival={true} />
          </Box>
        </Paper>
      </TabPanel>
      <TabPanel value={value} index={tabIndex.tabSix}>
        <Paper>
          <Box p={1}>
            <DwellChart data={chartSelectedData} untilNextLocation={false} />
          </Box>
        </Paper>
        <Paper>
          <Box mt={2} p={1}>
            <DwellChart data={chartSelectedData} untilNextLocation={true} />
          </Box>
        </Paper>
      </TabPanel>
    </div>
  );
}
