import TrainJourneysModel, {
  CriticalSystem
} from "./../models/train-journeys.model";
import JourneyFaultsCounter from "./../models/journey-faults-counter.model";

/***
 * @description Searches for faults triggered between station index and station index + 1
 * @param journey The journey to search
 * @param index The index of the station that might have a fault
 * @param counter Where to store any results ( Delegate initialization and storage to caller )
 */
export function searchForJourneyFaults(
  journey: TrainJourneysModel,
  index: number,
  counter: JourneyFaultsCounter[]
): JourneyFaultsCounter[] {
  // Might have more than one fault, check if any has occurred between the current station and next
  journey.criticalSystems.forEach((cs: CriticalSystem, csIndex: number) => {
    // Check if date is valid
    const date = new Date(cs.date);
    if (
      index !== journey.stops.length - 1 &&
      date > new Date("1910-01-01") &&
      new Date(journey.stops[index].actualArrival) <= date &&
        // (new Date(journey.stops[index].actualDeparture) <= date &&
        new Date(journey.stops[index + 1].actualArrival) >= date
    ) {
      const wsOccurrencePoint = counter.find(
        value => value.stationIndex === index
      );

      if (wsOccurrencePoint) {
        wsOccurrencePoint.faultsCounter++;
        wsOccurrencePoint.index
          ? wsOccurrencePoint.index.push(csIndex)
          : (wsOccurrencePoint.index = [csIndex]);
      } else {
        counter.push({
          stationIndex: index,
          faultsCounter: 1,
          index: [csIndex]
        });
      }
    }
  });

  return counter;
}
