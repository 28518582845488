import React from "react";

// material
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const SelectField = ({
  field,
  form,
  id,
  label,
  helpText,
  options = [],
  defaultValue = ""
}: {
  field: any;
  form: any;
  id: string;
  label: string;
  helpText: string;
  options: Array<object>;
  defaultValue: string;
}) => {
  const currentError = form.errors[field.name];

  return (
    <FormControl fullWidth required>
      <InputLabel id={`label-${id}`} htmlFor={id}>
        {label}
      </InputLabel>
      <Select
        labelId={`label-${id}`}
        id={id}
        name={field.name}
        error={Boolean(currentError)}
        onError={error => {
          // handle as a side effect
          if (error !== currentError) {
            form.setFieldError(field.name, error);
          }
        }}
        onChange={event => {
          form.setFieldValue(field.name, event.target.value, true);
        }}
        defaultValue={defaultValue}
      >
        {options.map((option: any, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.title}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{helpText}</FormHelperText>
    </FormControl>
  );
};

export default SelectField;
