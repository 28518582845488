// @see https://jasonwatmore.com/post/2019/02i/01/react-role-based-authorization-tutorial-with-example
import { BehaviorSubject } from "rxjs";

import { handleResponse } from "./../_helpers/handle-response";

const currentUserSubject = new BehaviorSubject(
  JSON.parse(`${localStorage.getItem("currentUser")}`)
);

export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  }
};

function login(username: string, password: string) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password })
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/users/authenticate`,
    requestOptions
  )
    .then(handleResponse)
    .then(user => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem("currentUser", JSON.stringify(user));
      currentUserSubject.next(user);

      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("currentUser");
  currentUserSubject.next(null);
  window.location.reload();
}
