import { authHeader } from "./../_helpers/auth-header";
import { handleResponse } from "./../_helpers/handle-response";

// models
import DwellRelatedStatisticsModel from "./../models/dwell-related-statistics.model";
import DwellRelatedStatisticsResponseModel from "../models/dwell-related-statistics-response.model";
import AmberRequestModel from "../models/amber.request.model";
import AmberResponseModel from "../models/amber.response.model";

export const dashboardService = {
  getDwellRelatedStatistics,
  getAmberTableData
};

function getDwellRelatedStatistics(params: DwellRelatedStatisticsModel) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  const queryParams = Object.values(params)
    .map(value => encodeURIComponent(value))
    .join("/");

  return fetch(
    `${process.env.REACT_APP_API_URL}/TrainJourneys/${queryParams}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<DwellRelatedStatisticsResponseModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}

function getAmberTableData(params: AmberRequestModel) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", ...authHeader() }
  };

  const queryParams = Object.values(params)
    .map(value => encodeURIComponent(value))
    .join("/");

  return fetch(
    `${process.env.REACT_APP_API_URL}/TrainJourneys/${queryParams}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res: Array<AmberResponseModel>) => {
      return res;
    })
    .catch(error => {
      return { error };
    });
}
