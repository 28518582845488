import React, { useState, useEffect } from "react";

// material
import Box from "@material-ui/core/Box";

// Unity
import Unity from "src/unity/exports";
import { UnityContext } from "src/unity/models/unity-context";

const unityContext = new UnityContext({
  loaderUrl: "/unity/Build/Pulse.loader.js",
  dataUrl: "/unity/Build/Pulse.data",
  frameworkUrl: "/unity/Build/Pulse.framework.js",
  codeUrl: "/unity/Build/Pulse.wasm"
});

export default function MappingTool() {
  const [progression, setProgression] = useState(0);

  useEffect(function() {
    unityContext.on("progress", (progression: number) => {
      if (progression) {
        let p = progression * 100;
        setProgression(p);
      }
    });
  }, []);

  return (
    <Box p={1}>
      {progression !== 100 && <p>Loading {progression.toPrecision(2)}%</p>}
      <Unity unityContext={unityContext} style={{ width: "100%" }} />
    </Box>
  );
}
