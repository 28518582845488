import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Chart from "react-apexcharts";

// material
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

// models
import { Range, Order } from "./../../../models/shared";
import DwellRelatedStatisticsModel from "./../../../models/dwell-related-statistics.model";
import DwellRelatedStatisticsResponseModel from "./../../../models/dwell-related-statistics-response.model";

// services
import { dashboardService } from "./../../../services/dashboard.service";
import { secondsToTime } from "./../../../_helpers/date-formatter";

const useStyles = makeStyles((theme: Theme) => ({
  loading: {
    textAlign: "center"
  }
}));

export default function DwellRelatedStatisticsChart({
  order,
  range,
  ...props
}: {
  order: Order;
  range: Range;
}) {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dwellRelatedStatistics, setDwellRelatedStatistics] = useState<
    Array<DwellRelatedStatisticsResponseModel>
  >([]);

  const [seriesData, setSeriesData] = useState<Array<any>>([]);
  const [seriesLabels, setSeriesLabels] = useState<Array<any>>([]);

  const xAxisTitle = "Dwell Delay(secs)";
  const yAxisTitle = "AVG Time (secs)";

  useEffect(() => {
    setError(null);
    setLoading(true);

    const params: DwellRelatedStatisticsModel = {
      range,
      order
    };

    dashboardService
      .getDwellRelatedStatistics(params)
      .then((res: Array<DwellRelatedStatisticsResponseModel> | any) => {
        setLoading(false);

        if (res.error) {
          setError(res.error);
        } else {
          setDwellRelatedStatistics(
            res
              .filter(
                (r: DwellRelatedStatisticsResponseModel) => r.count >= 100
              )
              .slice(0, 10)
          );
        }
      });
  }, [range, order]);

  useEffect(() => {
    setSeriesData([
      {
        name: "Section delay:",
        data: dwellRelatedStatistics.map(value => value.mean * 1)
      }
    ]);

    setSeriesLabels(
      dwellRelatedStatistics.map(
        data => `
        <strong>Average time:</strong>${data.mean.toFixed(2)}
        <br/> <strong>Stations count:</strong> ${data.count}
        <br/> <strong>Best:</strong> ${secondsToTime(
          data.min
        )} <br/> <strong>Worst:</strong> ${secondsToTime(data.max)}
        <br/> <strong>Standard deviation:</strong> ${secondsToTime(data.stDev)}
      `
      )
    );
  }, [dwellRelatedStatistics, order]);

  const chart = {
    options: {
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 20000,
                color: "#DC143C"
              },
              {
                from: -20000,
                to: 0,
                color: "#32CD32"
              }
            ]
          },
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle,
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) =>
            history.push(
              `/dashboard/station-dashboard/${
                dwellRelatedStatistics[config.dataPointIndex].name
              }/${range}`
            )
        }
      },
      xaxis: {
        type: "category" as "category",
        categories: dwellRelatedStatistics.map(value => value.name),
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle,
          offsetX: 10,
          style: {
            fontSize: "10px"
          }
        },
        labels: {
          formatter: (val: number) => val.toFixed(0),
          offsetX: 6,
          rotate: -45,
          style: {
            fontSize: "10px"
          }
        }
      },
      markers: {
        size: 4
      },
      tooltip: {
        custom: function(dataObject: any) {
          return `<div style="padding:0.5rem;">${
            dataObject.w.config.chartCustomLabels[dataObject.dataPointIndex]
          }</div>`;
        }
      },
      chartCustomLabels: seriesLabels
    },
    series: seriesData
  };

  return (
    <div>
      {loading && (
        <Box mt={3} className={classes.loading}>
          <CircularProgress size={24} />
        </Box>
      )}

      {error && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          An error has occurred. Please try again, and if the problem persists,
          contact your help desk or system administrator.{" "}
          <p>
            {" "}
            (<strong>{error}</strong>)
          </p>
        </Alert>
      )}

      {!loading &&
        dwellRelatedStatistics &&
        dwellRelatedStatistics.length > 0 &&
        seriesData.length > 0 && (
          <Chart
            options={chart.options}
            series={chart.series}
            type="bar"
            height="350"
          />
        )}
    </div>
  );
}
