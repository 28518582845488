export const stationsService = {
  getTrainStations
};

function getTrainStations() {
  return {
    ABW: "Abbey Wood",
    ADM: "Adisham",
    AFK: "Ashford KENT",
    AYH: "Aylesham",
    AYL: "Aylesford",
    AYP: "Albany Park",
    BAT: "Battle",
    BCH: "Birchington on Sea",
    BEG: "Beltring",
    BFR: "Blackfriars",
    BGM: "Bellingham",
    BKH: "Blackheath",
    BKJ: "Beckenham Junction Station",
    BKL: "Bickley",
    BKS: "Bekesbourne",
    BMG: "Barming",
    BMS: "Bromley South",
    BNH: "Barnehurst",
    BRG: "Borough Green & Wrotham",
    BRX: "Brixton",
    BSD: "Bearsted",
    BSR: "Broadstairs",
    BVD: "Belvedere",
    BXH: "Bexleyheath",
    BXY: "Bexley",
    CBE: "Canterbury East",
    CBW: "Canterbury West",
    CFB: "Catford / Catford Bridge",
    CFT: "Crofton Park",
    CHX: "London Charing Cross",
    CHG: "Charing",
    CIL: "Chilham",
    CIT: "Chislehurst",
    CLD: "Chelsfield",
    CLK: "Clock House",
    CLP: "Clapham High Street",
    CRT: "Chartham",
    CRY: "Crayford",
    CST: "London Cannon Street",
    CSW: "Chestfield & Swalecliffe",
    CTM: "Chatham",
    CTN: "Charlton",
    CUX: "Cuxton",
    CWU: "Crowhurst",
    DEA: "Deal",
    DEP: "Deptford",
    DFD: "Dartford",
    DMK: "Denmark Hill",
    DMP: "Dumpton Park",
    DNG: "Dunton Green",
    DVP: "Dover Priory",
    EDN: "Eden Park",
    EFL: "East Farleigh",
    ELE: "Elmers End",
    ELW: "Eltham",
    EML: "East Malling",
    EPH: "Elephant & Castle",
    ERH: "Erith",
    ESD: "Elmstead Woods",
    ETC: "Etchingham",
    FAV: "Faversham",
    FCN: "Falconwood",
    FKC: "Folkestone Central",
    FKW: "Folkestone West",
    FNR: "Farningham Road",
    FRT: "Frant",
    GLM: "Gillingham",
    GNH: "Greenhithe for Bluewater",
    GNW: "Greenwich",
    GRP: "Grove Park",
    GRV: "Gravesend",
    HAI: "Halling",
    HBN: "Hollingbourne",
    HCN: "Headcorn",
    HGM: "Higham",
    HGR: "Hither Green",
    HGS: "Hastings",
    HIB: "High Brooms",
    HLB: "Hildenborough",
    HNB: "Herne Bay",
    HNH: "Herne Hill",
    HRM: "Harrietsham",
    HYS: "Hayes",
    KCK: "Knockholt",
    KDB: "Kidbrooke",
    KMS: "Kemsing",
    KSN: "Kearsney",
    KTH: "Kent House",
    LAD: "Ladywell",
    LBG: "London Bridge",
    LEE: "Lee",
    LEN: "Lenham",
    LEW: "Lewisham",
    LGF: "Longfield",
    LSY: "Lower Sydenham",
    MAR: "Margate",
    MDB: "Maidstone Barracks",
    MDE: "Maidstone East",
    MDW: "Maidstone West",
    MEP: "Meopham",
    MRN: "Marden",
    MSR: "Minster",
    MTG: "Mottingham",
    MTM: "Martin Mill",
    MZH: "Maze Hill",
    NBC: "New Beckenham",
    NEH: "New Eltham",
    NFL: "Northfleet",
    NGT: "Newington",
    NHE: "New Hythe",
    NWX: "New Cross",
    ORE: "Ore",
    ORP: "Orpington",
    OTF: "Otford",
    PDW: "Paddock Wood",
    PET: "Petts Wood",
    PLC: "Pluckley",
    PLU: "Plumstead",
    PNE: "Penge East",
    RAI: "Rainham KENT",
    RAM: "Ramsgate",
    RBR: "Robertsbridge",
    RTR: "Rochester",
    SAJ: "St John",
    SAY: "Swanley",
    SCG: "Stone Crossing",
    SDA: "Snodland",
    SDG: "Sandling",
    SDW: "Sandwich",
    SEG: "Selling",
    SEV: "Sevenoaks",
    SGR: "Slade Green",
    SID: "Sidcup",
    SIT: "Sittingbourne",
    SLQ: "St Leonards Warrior Square",
    SMY: "St Mary Cray",
    SOG: "Stonegate",
    SOO: "Strood",
    SOR: "Sole Street",
    SPH: "Shepherds Well",
    SPU: "Staplehurst",
    SRT: "Shortlands",
    STU: "Sturry",
    SWM: "Swanscombe",
    SWO: "Snowdown",
    SYH: "Sydenham Hill",
    TBW: "Tunbridge Wells",
    TEY: "Teynham",
    TON: "Tonbridge",
    VIC: "London Victoria",
    WAD: "Wadhurst",
    WAE: "London Waterloo East",
    WAM: "Walmer",
    WCB: "Westcombe Park",
    WDU: "West Dulwich",
    WGA: "Westgate-on-Sea",
    WHA: "Westenhanger",
    WHI: "Whitstable",
    WLD: "West St Leonards",
    WLI: "Welling",
    WMA: "West Malling",
    WTR: "Wateringbury",
    WWA: "Woolwich Arsenal",
    WWD: "Woolwich Dockyard",
    WWI: "West Wickham",
    WWR: "Wandsworth Road",
    WYE: "Wye",
    YAL: "Yalding"
  };
}
