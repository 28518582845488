import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import { SingleStationSearchProject } from "src/models/single-station-search-response.model";

// services
import { findClosestStopByTimeDifference } from "src/_helpers/find-closest-stop";

export default function LatenessDistributionOfServicesChart({
  data,
  ...props
}: {
  data: Array<SingleStationSearchProject>;
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);

  const xAxisTitle = "Lateness (seconds)";
  const yAxisTitle = "Number of services";

  useEffect(() => {
    const seriesArrayData = new Map<number, number>();
    const seconds: number[] = [
      0,
      30,
      60,
      90,
      120,
      5 * 30,
      6 * 30,
      7 * 30,
      8 * 30,
      9 * 30,
      10 * 30,
      11 * 30,
      12 * 30,
      13 * 30,
      14 * 30,
      15 * 30,
      16 * 30,
      17 * 30,
      18 * 30,
      19 * 30,
      20 * 30
    ];
    seconds.sort((a, b) => (a > b ? 1 : -1));
    seconds.forEach(value => {
      seriesArrayData.set(value, 0);
    });

    data.forEach(value => {
      const closestValue = findClosestStopByTimeDifference(
        seconds,
        value.stationArrDiff
      );
      const setValue = seriesArrayData.get(closestValue);
      seriesArrayData.set(closestValue, setValue ? setValue + 1 : 1);
    });

    setSeriesData([
      {
        name: "Number of services",
        data: [...seriesArrayData.values()]
      }
    ]);
  }, [data]);

  const chart = {
    options: {
      dataLabels: {
        enabled: false
      },
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: [
          0,
          30,
          60,
          90,
          120,
          5 * 30,
          6 * 30,
          7 * 30,
          8 * 30,
          9 * 30,
          10 * 30,
          11 * 30,
          12 * 30,
          13 * 30,
          14 * 30,
          15 * 30,
          16 * 30,
          17 * 30,
          18 * 30,
          19 * 30,
          20 * 30 + "+"
        ],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) => val.toFixed(0)
        }
      },
      markers: {
        size: 4
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="bar"
      height="350"
    />
  );
}
