import React, { useState } from "react";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// components
import ChartTabs from "./charts/chart-tabs";
import ServicesTable from "./services-table";

// models
import SingleStationSearchResponse from "src/models/single-station-search-response.model";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      marginTop: 4,
      marginLeft: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(2)
      // textAlign: 'center',
    }
  })
);

export default function Dashboard({
  data,
  station,
  ...props
}: {
  data: SingleStationSearchResponse;
  station: any;
}) {
  const classes = useStyles();
  const [tableFilter, setTableFilter] = useState();

  const clearFilter = () => {
    setTableFilter(undefined);
  };

  return (
    <Box mt={3}>
      <Paper className={classes.paper}>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" align="center" color="textPrimary">
                    {station.title}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                  >
                    Station name
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h4" align="center" color="textPrimary">
                    {station.value}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="center"
                    color="textSecondary"
                  >
                    Station CRS
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="h6" color="textPrimary">
                    {data.singleStationSearchGeneralGrouping?.arrivalAvgLateness.toFixed(
                      2
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Mean arrival lateness
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="h6" color="textPrimary">
                    {data.singleStationSearchGeneralGrouping?.dwellAvgLateness.toFixed(
                      2
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Mean duration dwell
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="h6" color="textPrimary">
                    {data.singleStationSearchGeneralGrouping?.departureAvgLateness.toFixed(
                      2
                    )}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Mean departure lateness
                  </Typography>
                </Grid>
                <Grid item md={3}>
                  <Typography variant="h6" color="textPrimary">
                    {data.services.length}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    color="textSecondary"
                  >
                    Service volume
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <Grid container spacing={1}>
                <Grid item md={6}></Grid>
                <Grid item md={6}>
                  {tableFilter && (
                    <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                      <Box p={1}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={clearFilter}
                        >
                          Clear Filter
                        </Button>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6}>
              <ChartTabs data={data} passTableFilter={setTableFilter} />
            </Grid>
            <Grid item md={6}>
              <ServicesTable data={data.services} filter={tableFilter} />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Box>
  );
}
