import { authenticationService } from "src/services/authentication.service";

// const user = authenticationService.currentUserValue;

export const authorisationService = {
  hasOwnerAccess,
  hasSuperAdminAccess,
  hasAdminAccess,
  hasModeratorAccess,
  hasUserAccess
};

/**
 * Check if user is type of Owner
 *
 * Owner users have full access to all sections
 */
function hasOwnerAccess() {
  return authenticationService.currentUserValue.role === "Owner";
}

/**
 * Check if user is type of Super Admin
 *
 * SuperAdmins are system maintainer users. They can access system related
 * views and logs
 */
function hasSuperAdminAccess() {
  return (
    authenticationService.currentUserValue.role === "SuperAdmin" ||
    hasOwnerAccess()
  );
}

/**
 * Check if user is type of Admin
 *
 * Admins are application administrators
 */
function hasAdminAccess() {
  return (
    authenticationService.currentUserValue.role === "Admin" ||
    hasOwnerAccess() ||
    hasSuperAdminAccess()
  );
}

/**
 * Check if user is type of Moderator
 *
 * Moderators are application moderators with read/write access.
 */
function hasModeratorAccess() {
  return (
    authenticationService.currentUserValue.role === "Moderator" ||
    hasOwnerAccess() ||
    hasSuperAdminAccess() ||
    hasAdminAccess()
  );
}

/**
 * Check if user is type of User
 *
 * Users are application users with read only access.
 */
function hasUserAccess() {
  return (
    authenticationService.currentUserValue.role === "User" ||
    hasOwnerAccess() ||
    hasSuperAdminAccess() ||
    hasAdminAccess() ||
    hasModeratorAccess()
  );
}
