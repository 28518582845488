import React from "react";

// material
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { getIn } from "formik";

const AutocompleteField = ({
  field,
  form: { touched, errors, setFieldValue },
  options = [],
  ...props
}: {
  field: any;
  form: any;
  options: Array<any>;
}) => {
  const errorText = getIn(errors, field.name);
  const touchedVal = getIn(touched, field.name);
  const hasError = touchedVal && errorText !== undefined;

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: any) => `${option.value}${option.title}`
  });

  return (
    <Autocomplete
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option: any) => option.title}
      getOptionSelected={(option, val) => {
        return option.title === val.title;
      }}
      onChange={(event: any, option: any) => {
        if (option) {
          setFieldValue(field.name, option.title, true);
        } else {
          setFieldValue(field.name, null, true);
        }
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            {...props}
            error={hasError}
            helperText={hasError ? errorText : ""}
          />
        );
      }}
    />
  );
};

export default AutocompleteField;
