import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "./../../../../models/train-journeys.model";

export default function PercentilesChart({
  data,
  outgoing,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  outgoing: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  const dataSource = outgoing ? "departureDiff" : "arrivalDiff";
  const xAxisTitle = outgoing ? "Departures" : "Arrivals";

  const numberSort = (a: number, b: number) => {
    return a - b;
  };

  const getJourneyStopDataAtIndex = (
    journeyStop: Array<any>,
    numerator: number
  ) => {
    const stopDataArray = journeyStop.map(stop => 0);
    let arrayIndex = Math.round(journeyStop[0].length * (numerator / 100));

    if (numerator === 100) {
      arrayIndex = journeyStop[0].length - 1;
    }

    journeyStop.forEach((stopAtServices, i) => {
      stopDataArray[i] = stopAtServices[arrayIndex];
    });

    return stopDataArray;
  };

  useEffect(() => {
    const journeyStops = data[0].stops.map(stop => [0]);

    data.forEach((journey: TrainJourneysModel, journeyIndex: number) => {
      journey.stops.forEach((stop, index: number) => {
        journeyStops[index].push(stop[dataSource] * 1);
      });
    });

    journeyStops.forEach((stop, index) => {
      stop.sort(numberSort);
    });

    setSeriesData([
      {
        name: "15%",
        data: getJourneyStopDataAtIndex(journeyStops, 15)
      },
      {
        name: "30%",
        data: getJourneyStopDataAtIndex(journeyStops, 30)
      },
      {
        name: "50%",
        data: getJourneyStopDataAtIndex(journeyStops, 50)
      },
      {
        name: "90%",
        data: getJourneyStopDataAtIndex(journeyStops, 90)
      },
      {
        name: "100%",
        data: getJourneyStopDataAtIndex(journeyStops, 100)
      }
    ]);
  }, [data, dataSource]);

  const chart = {
    options: {
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: data[0].stops.map(stops => stops.name),
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: "Lateness (secs)"
        }
      },
      markers: {
        size: 4
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="line"
      height="350"
    />
  );
}
