import React, { useEffect, useState } from "react";

// material
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";

// models
import { Range, Order } from "../../../models/shared";
import AmberRequestModel from "../../../models/amber.request.model";
import AmberResponseModel from "../../../models/amber.response.model";

// services
import { dashboardService } from "./../../../services/dashboard.service";

// components
import AmberTable from "./amber-table";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: "850px"
  },
  chartHeader: {
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  rangeTransform: {
    textTransform: "capitalize"
  },
  loading: {
    textAlign: "center"
  }
}));

export default function AmberView({ ...props }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  // const [range, setRange] = useState<Range>(Range.weekly);
  // const [order, setOrder] = useState<Order>(Order.asc);
  // const [orderDesc, setOrderDesc] = useState<Order>(Order.desc);
  // const [headCodeDigits, setHeadCodeDigits] = useState<number>(6);
  // const [rangeLabel, setRangeLabel] = useState("week");
  const [tableDataAsc, setTableDataAsc] = useState<Array<AmberResponseModel>>(
    []
  );
  const [tableDataDesc, setTableDataDesc] = useState<Array<AmberResponseModel>>(
    []
  );

  // const handleChange = (event: any) => {
  //   setRange(event.target.value);

  //   event.target.value === Range.weekly
  //     ? setRangeLabel("week")
  //     : setRangeLabel("month");
  // };

  useEffect(() => {
    // setError(null);
    // setLoading(true);

    const paramsAsc: AmberRequestModel = {
      range: Range.weekly,
      order: Order.asc,
      headCodeDigits: 4
    };

    const paramsDesc: AmberRequestModel = {
      range: Range.weekly,
      order: Order.desc,
      headCodeDigits: 4
    };

    dashboardService
      .getAmberTableData(paramsAsc)
      .then((res: Array<AmberResponseModel> | any) => {
        setLoading(false);
        setTableDataAsc(res);
      });

    dashboardService
      .getAmberTableData(paramsDesc)
      .then((res: Array<AmberResponseModel> | any) => {
        setLoading(false);
        setTableDataDesc(res);
      });

    return () => {};
  }, []);

  return (
    <div className={classes.root}>
      {/* <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
      >
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">View range</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={range}
            className={classes.rangeTransform}
            onChange={handleChange}
          >
            <MenuItem value={Range.weekly} className={classes.rangeTransform}>
              {Range.weekly}
            </MenuItem>
            <MenuItem value={Range.monthly} className={classes.rangeTransform}>
              {Range.monthly}
            </MenuItem>
          </Select>
        </FormControl>
      </Grid> */}
      <Paper>
        {loading && (
          <Box mt={3} className={classes.loading}>
            <CircularProgress size={24} />
          </Box>
        )}

        {!loading && tableDataAsc && tableDataAsc.length > 0 && (
          <div>
            <Box mt={3}>
              <AmberTable
                data={tableDataAsc}
                title="Last week's worst services"
                sortOrder="asc"
              />
            </Box>
            <Box mt={3}>
              <AmberTable
                data={tableDataDesc}
                title="Last week's best services"
                sortOrder="desc"
              />
            </Box>
          </div>
        )}
      </Paper>
    </div>
  );
}
