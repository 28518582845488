import React from "react";

// material
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// models
import TrainJourneysModel from "./../../../models/train-journeys.model";

// services
import { getTime, secondsToTime } from "./../../../_helpers/date-formatter";
import {
  getComparator,
  sortArrayOfObjects
} from "./../../../_helpers/sort-array-objects";

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  align: "left" | "center" | "right" | "inherit" | "justify" | undefined;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Station stop",
    align: "center"
  },
  {
    id: "bookedArrival",
    numeric: false,
    disablePadding: false,
    label: "Booked Arrival",
    align: "center"
  },
  {
    id: "actualArrival",
    numeric: false,
    disablePadding: false,
    label: "Actual",
    align: "center"
  },
  {
    id: "bookedDeparture",
    numeric: false,
    disablePadding: false,
    label: "Booked Departure",
    align: "center"
  },
  {
    id: "actualDeparture",
    numeric: false,
    disablePadding: false,
    label: "Actual",
    align: "center"
  },
  {
    id: "untilNextLocationBookedTime",
    numeric: false,
    disablePadding: false,
    label: "Booked until next station",
    align: "center"
  },
  {
    id: "untilNextLocationActualTime",
    numeric: false,
    disablePadding: false,
    label: "Actual",
    align: "center"
  },
  {
    id: "dwellBooked",
    numeric: false,
    disablePadding: false,
    label: "Booked Dwell time",
    align: "center"
  },
  {
    id: "dwellActual",
    numeric: false,
    disablePadding: false,
    label: "Actual",
    align: "center"
  }
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TrainJourneysModel["stops"]
  ) => void;
  order: Order;
  orderBy: any;
  rowsPerPage: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof TrainJourneysModel["stops"]) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
            size="medium"
            className={`${classes.headCell} ${classes.tableCell}`}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2)
    },
    table: {
      minWidth: 750
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1
    },
    headCell: {
      lineHeight: "1.2rem"
    },
    tableCell: {
      padding: theme.spacing(1)
    }
  })
);

export default function JourneyDetailsTable({
  data
}: {
  data: TrainJourneysModel["stops"];
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof any>("bookedArrival");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TrainJourneysModel["stops"]
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography variant="h6" id="tableTitle">
            Journey details
          </Typography>
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowsPerPage={rowsPerPage}
            />
            <TableBody>
              {sortArrayOfObjects(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // const uniqueIdentifier = `${row.date}-${row.faultCode}`;
                  // const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover role="row" tabIndex={-1} key={index}>
                      <TableCell className={classes.tableCell} align="center">
                        {row.name}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {getTime(row.bookedArrival) === `00:00:00`
                          ? `N/A`
                          : getTime(row.bookedArrival)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {getTime(row.actualArrival)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {getTime(row.bookedDeparture) === `00:00:00`
                          ? `N/A`
                          : getTime(row.bookedDeparture)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {getTime(row.actualDeparture)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.untilNextLocationBookedTime)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.untilNextLocationActualTime)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.dwellBooked)}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {secondsToTime(row.dwellActual)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
