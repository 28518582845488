import React from "react";
import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";

// material
import { Alert, AlertTitle } from "@material-ui/lab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

// assets
// import styles from './filters-form.module.css';

// components
import AutocompleteField from "../../shared/formik/autocomplete";
import CheckBoxFieldWithLabel from "../../shared/formik/checkbox";
import DatePickerField from "../../shared/formik/date-picker";
import MaterialInputField from "../../shared/formik/input";
import SelectField from "../../shared/formik/select";
import ResultsTable from "./results-table";

// models
import { peakStrings } from "./../../../models/peak-filters-values";
import StoppingPatternsRequest from "./../../../models/stopping-patterns-request.model";
import StoppingPatternsResponseModel from "../../../models/stopping-patterns-response.model";

// services
import { stationsService } from "src/services/stations.service";
import getStoppingPatterns from "src/services/stopping-patterns.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      marginTop: -12,
      marginLeft: theme.spacing(2)
    }
  })
);

export default function FiltersForm() {
  const classes = useStyles();
  const state = {
    origin: "",
    terminus: "",
    headcode: "",
    peak_time: "",
    days: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false
    },
    checkedAll: true
  };

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [journeyResults, setJourneyResults] = React.useState<
    Array<StoppingPatternsResponseModel>
  >();

  const [params, setParams] = React.useState<StoppingPatternsRequest>();

  const defaultStartDate = new Date();
  defaultStartDate
    .setDate(defaultStartDate.getDate() - 8)
    .toLocaleString("en-GB");

  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() - 1).toLocaleString("en-GB");

  const defaultStartDatePeriod2 = new Date();
  defaultStartDatePeriod2
    .setDate(defaultStartDatePeriod2.getDate() - 16)
    .toLocaleString("en-GB");

  const defaultEndDatePeriod2 = new Date();
  defaultEndDatePeriod2
    .setDate(defaultEndDatePeriod2.getDate() - 9)
    .toLocaleString("en-GB");

  const minSelectDate = new Date();
  minSelectDate.setMonth(minSelectDate.getMonth() - 24).toLocaleString("en-GB");

  const daysOfTheWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const dayCheckboxes: any[] = [];

  for (const [index, value] of daysOfTheWeek.entries()) {
    dayCheckboxes.push(
      <Field
        key={index}
        name={"days[" + value + "]"}
        label={value}
        value={value}
        component={CheckBoxFieldWithLabel}
      />
    );
  }

  const stations = Object.entries(stationsService.getTrainStations());
  const stationOptions: any[] = [];
  for (let [key, value] of stations) {
    stationOptions.push({
      value: key,
      title: value
    });
  }

  // sort by title
  stationOptions.sort((a, b) => {
    return ("" + a.title).localeCompare(b.title);
  });

  const peakTimes = Object.entries(peakStrings);
  const peakOptions: any[] = [];
  for (let [key, option] of peakTimes) {
    peakOptions.push({
      value: option.value,
      title: option.name,
      key
    });
  }

  const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());
  const isValidDateRange = (d: any) => {
    const validateDate = new Date(d).setHours(0, 0, 0, 0);
    const minDate = new Date(minSelectDate).setHours(0, 0, 0, 0);
    const maxDate = new Date(defaultEndDate).setHours(0, 0, 0, 0);
    return validateDate >= minDate && validateDate <= maxDate;
  };

  return (
    <div>
      {error && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          An error has occurred. Please try again, and if the problem persists,
          contact your help desk or system administrator.{" "}
          <p>
            {" "}
            (<strong>{error}</strong>)
          </p>
        </Alert>
      )}
      <h1>Route parameters</h1>
      <Typography variant="body2" gutterBottom>
        Search for a route to compare results in two different date ranges
      </Typography>
      <Formik
        initialValues={{
          origin: "",
          terminus: "",
          start_date: defaultStartDate,
          end_date: defaultEndDate,
          start_date_to_compare: defaultStartDatePeriod2,
          end_date_to_compare: defaultEndDatePeriod2,
          peak_time: 32,
          headcode: "",
          days: {
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true
          }
        }}
        validate={values => {
          const errors: any = {};
          if (!values.start_date) {
            errors.start_date = "Required";
          } else if (
            !isValidDate(values.start_date) ||
            !isValidDateRange(values.start_date)
          ) {
            errors.start_date = true;
          }

          if (!values.end_date) {
            errors.end_date = "Required";
          } else if (
            !isValidDate(values.end_date) ||
            !isValidDateRange(values.end_date)
          ) {
            errors.end_date = true;
          }
          if (!values.start_date_to_compare) {
            errors.start_date_to_compare = "Required";
          } else if (
            !isValidDate(values.start_date_to_compare) ||
            !isValidDateRange(values.start_date_to_compare)
          ) {
            errors.start_date_to_compare = true;
          }

          if (!values.end_date_to_compare) {
            errors.end_date_to_compare = "Required";
          } else if (
            !isValidDate(values.end_date_to_compare) ||
            !isValidDateRange(values.end_date_to_compare)
          ) {
            errors.end_date_to_compare = true;
          }

          if (!values.origin) {
            errors.origin = "Required";
          }

          if (!values.terminus) {
            errors.terminus = "Required";
          }

          if (values.headcode && values.headcode !== "any") {
            if (!values.headcode.match(/^[0-9a-zA-Z]+$/)) {
              errors.headcode = "Please input alphanumeric characters only";
            } else if (values.headcode.length > 6) {
              errors.headcode = "Maximum number of allowed characters is 6";
            } else if (values.headcode.length < 2) {
              errors.headcode = "Minimum number of allowed character/s is 2";
            }
          }

          const days = Object.entries(values.days);
          let daysSet = false;
          for (let option of days) {
            if (option) {
              daysSet = true;
              break;
            }
          }

          if (!daysSet) {
            errors.days = "At least one day has to be selected";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setError(null);
          setJourneyResults(undefined);

          // fix for station names
          values.origin = stationOptions.find(
            s => s.title === values.origin
          ).value;
          values.terminus = stationOptions.find(
            s => s.title === values.terminus
          ).value;

          const params: any = { ...values };

          params.start_date = values.start_date.toISOString().split("T")[0];
          params.end_date = values.end_date.toISOString().split("T")[0];
          params.start_date_to_compare = values.start_date_to_compare
            .toISOString()
            .split("T")[0];
          params.end_date_to_compare = values.end_date_to_compare
            .toISOString()
            .split("T")[0];

          if (!params.headcode) {
            params.headcode = "any";
          }

          const days = Object.entries(values.days);
          let daysString = "";
          for (let [key, value] of days) {
            if (value) {
              daysString = daysString.concat(" ", key);
            }
          }
          params.days = daysString.trim();

          setParams(params);

          if (!loading) {
            setLoading(true);

            getStoppingPatterns(params).then(
              (res: Array<StoppingPatternsResponseModel> | any) => {
                setSubmitting(false);
                setLoading(false);

                if (res.error) {
                  setError(res.error.message ? res.error.message : res.error);
                } else {
                  setJourneyResults(res);
                }
              }
            );
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="start_date"
                    disableToolbar
                    id="start_date"
                    label="Start date range A"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={defaultEndDate}
                    component={DatePickerField}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="end_date"
                    id="end_date"
                    label="End date range A"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={defaultEndDate}
                    component={DatePickerField}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="start_date_to_compare"
                    disableToolbar
                    id="start_date_to_compare"
                    label="Start date range B"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={defaultEndDate}
                    component={DatePickerField}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="end_date_to_compare"
                    id="end_date_to_compare"
                    label="End date range B"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={defaultEndDate}
                    component={DatePickerField}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sm={3}>
                <Field
                  name="origin"
                  id="origin"
                  label="First station"
                  value={state.origin}
                  options={stationOptions}
                  component={AutocompleteField}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Field
                  name="terminus"
                  id="terminus"
                  label="Last station"
                  value={state.terminus}
                  options={stationOptions}
                  component={AutocompleteField}
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sm={3}>
                <Field
                  component={MaterialInputField}
                  type="text"
                  id="headcode"
                  label="Head code"
                  name="headcode"
                  value={state.headcode}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <Field
                  name="peak_time"
                  id="peak_time"
                  label="Peak time"
                  value={state.peak_time}
                  options={peakOptions}
                  component={SelectField}
                  defaultValue="32"
                />
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                {dayCheckboxes}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedAll}
                      onChange={event => {
                        state.checkedAll = !state.checkedAll;
                        // setState(state)
                        dayCheckboxes.forEach(cb => {
                          setFieldValue(
                            cb.props.name,
                            event.target.checked,
                            false
                          );
                        });
                      }}
                      value="all"
                    />
                  }
                  label="All"
                />
                <p className="MuiFormHelperText-root Mui-error">
                  {errors.days}
                </p>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                {journeyResults && !journeyResults.length && (
                  <Alert severity="info" onClose={() => {}}>
                    <AlertTitle>Info</AlertTitle>
                    There are no journeys for defined parameters. Please try
                    defining different route parameters.
                  </Alert>
                )}
                <Box mb={2} />

                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {loading ? "Searching ..." : "Search"}
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {journeyResults && journeyResults.length > 0 && params && (
        <Box mt={3}>
          <Alert severity="info">
            Please click to a route to view journey details.
          </Alert>
          <Box mb={2} />
          <ResultsTable data={journeyResults} params={params} />
        </Box>
      )}
    </div>
  );
}
