import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { useParams } from "react-router-dom";

// models
import ServicesResponseModel from "src/models/services-response.model";
import ServicesRequest from "src/models/services-request.model";

export default function PercentilesChart({
  data,
  ...props
}: {
  data: ServicesResponseModel;
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  const xAxisTitle = "Arrivals/Departures";
  const categories: Array<string> = [];

  data["period-A-services"][0].stops.forEach((stop, index) => {
    if (!index) {
      categories.push(`${stop.name} O`);
    } else if (index === data["period-A-services"][0].stops.length - 1) {
      categories.push(`${stop.name} T`);
    } else {
      categories.push(`${stop.name} A`);
      categories.push(`${stop.name} D`);
    }
  });

  const numberSort = (a: number, b: number) => {
    return a - b;
  };

  const getJourneyStopDataAtIndex = (
    journeyStop: Array<any>,
    index: number
  ) => {
    const stopDataArray = journeyStop.map(stop => 0);
    // let arrayIndex = Math.round(journeyStop[0].length * (numerator / 100));

    // if (numerator === 100) {
    //   arrayIndex = journeyStop[0].length - 1;
    // }

    journeyStop.forEach((stopAtServices, i) => {
      stopDataArray[i] = stopAtServices[index];
    });

    return stopDataArray;
  };

  const params: ServicesRequest = useParams<any>();
  const chartTitle = `${params.start_date} - ${params.end_date} (blue), ${params.start_date_to_compare} - ${params.end_date_to_compare} (yellow)`;

  useEffect(() => {
    const arrFlatData = data["period-A-services"][0].stops
      .map(stop => [0])
      .concat(data["period-A-services"][0].stops.map(stop => [0]));

    const arrFlatDataB = data["period-A-services"][0].stops
      .map(stop => [0])
      .concat(data["period-A-services"][0].stops.map(stop => [0]));

    const journeyStopsArrData = data["period-A-services"][0].stops.map(stop => [
      0
    ]);
    const journeyStopsDepData = data["period-A-services"][0].stops.map(stop => [
      0
    ]);
    const journeyStopsBArrData = data[
      "period-A-services"
    ][0].stops.map(stop => [0]);
    const journeyStopsBDepData = data[
      "period-A-services"
    ][0].stops.map(stop => [0]);

    data["period-A-services"].forEach((journey, journeyIndex: number) => {
      journey.stops.forEach((stop, index: number) => {
        // journeyStops[index].push(stop[dataSource] * 1);
        journeyStopsArrData[index].push(stop["arrivalDiff"] * 1);
        journeyStopsDepData[index].push(stop["departureDiff"] * 1);
      });
    });

    data["period-B-services"].forEach((journey, journeyIndex: number) => {
      journey.stops.forEach((stop, index: number) => {
        // journeyStopsB[index].push(stop[dataSource] * 1);
        journeyStopsBArrData[index].push(stop["arrivalDiff"] * 1);
        journeyStopsBDepData[index].push(stop["departureDiff"] * 1);
      });
    });

    // calculate 10% of total services
    let tenPercent = Math.floor(0.1 * journeyStopsDepData[0].length);

    journeyStopsArrData.forEach((stop, index) => {
      stop.splice(0, tenPercent);
      stop.splice(stop.length - tenPercent, tenPercent);
      stop.sort(numberSort);
    });

    journeyStopsDepData.forEach((stop, index) => {
      stop.splice(0, tenPercent);
      stop.splice(stop.length - tenPercent, tenPercent);
      stop.sort(numberSort);
    });

    journeyStopsBArrData.forEach((stop, index) => {
      stop.splice(0, tenPercent);
      stop.splice(stop.length - tenPercent, tenPercent);
      stop.sort(numberSort);
    });

    journeyStopsBDepData.forEach((stop, index) => {
      stop.splice(0, tenPercent);
      stop.splice(stop.length - tenPercent, tenPercent);
      stop.sort(numberSort);
    });

    arrFlatData.forEach((v: any, index: number) => {
      if (!index) {
        arrFlatData[index] = journeyStopsDepData[index];
        arrFlatDataB[index] = journeyStopsBDepData[index];
      } else if (index % 2) {
        arrFlatData[index] = journeyStopsArrData[(index + 1) / 2];
        arrFlatDataB[index] = journeyStopsBArrData[(index + 1) / 2];
      } else {
        arrFlatData[index] = journeyStopsDepData[index / 2];
        arrFlatDataB[index] = journeyStopsBDepData[index / 2];
      }
    });

    // We need one station for start and end
    arrFlatData.splice(arrFlatData.length - 2, 2);
    arrFlatDataB.splice(arrFlatDataB.length - 2, 2);

    setSeriesData([
      {
        name: "10% A",
        data: getJourneyStopDataAtIndex(arrFlatData, 0)
      },
      {
        name: "50% A",
        data: getJourneyStopDataAtIndex(
          arrFlatData,
          Math.floor(journeyStopsArrData[0].length / 2)
        )
      },
      {
        name: "90% A",
        data: getJourneyStopDataAtIndex(
          arrFlatData,
          journeyStopsArrData[0].length - 1
        )
      },
      {
        name: "10% B",
        data: getJourneyStopDataAtIndex(arrFlatDataB, 0)
      },
      {
        name: "50% B",
        data: getJourneyStopDataAtIndex(
          arrFlatDataB,
          Math.floor(journeyStopsBDepData[0].length / 2)
        )
      },
      {
        name: "90% B",
        data: getJourneyStopDataAtIndex(
          arrFlatDataB,
          journeyStopsBDepData[0].length - 1
        )
      }
    ]);
  }, [data]);

  const chart = {
    options: {
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      colors: [
        "#90caf9",
        "#2196f3",
        "#90caf9",
        "#ffd54f",
        "#ffb300",
        "#ffd54f"
      ],
      xaxis: {
        type: "category" as "category",
        categories: categories,
        labels: {
          show: true,
          rotate: -90,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: "Lateness (secs)"
        }
      },
      markers: {
        size: 4
      },
      stroke: {
        width: [1, 5, 1, 1, 5, 1]
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: chartTitle
      }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="line"
      height="550"
    />
  );
}
