import TrainJourneysModel, {
  JourneyStop
} from "./../models/train-journeys.model";
import { SingleStationSearchProject } from "src/models/single-station-search-response.model";
/**
 * @description Returns the number in the @seconds that is closest to the number provided as @difference
 * @param seconds The array that contains the seconds in numbers to find the closest one
 * @param difference The number to search or the closest value in @seconds
 */
export function findClosestStopByTimeDifference(
  seconds: Array<number>,
  difference:
    | JourneyStop["arrivalDiff"]
    | JourneyStop["departureDiff"]
    | TrainJourneysModel["endDateDifference"]
    | SingleStationSearchProject["stationArrDiff"]
): number {
  if (difference === 0) {
    return 0;
  }

  return seconds.reduce((a, b) => {
    let aDiff = Math.abs(a - difference);
    let bDiff = Math.abs(b - difference);

    if (aDiff === bDiff) {
      // Choose largest vs smallest (> vs <)
      return a > b ? a : b;
    } else {
      return bDiff < aDiff ? b : a;
    }
  });
}
