import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link,
  Redirect
  // useHistory,
  // useLocation
} from "react-router-dom";

import "./App.css";

// components
import SignInSide from "./components/signin/sign-in";
import Dashboard from "./components/dashboard/dashboard";
import { authenticationService } from "./services/authentication.service";

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <Route path="/">
          <SignInSide />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticationService.currentUserValue ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
