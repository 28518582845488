import React, { useEffect } from "react";
import Chart from "react-apexcharts";

// models
import TrainJourneysModel from "./../../../../models/train-journeys.model";
import JourneyFaultsCounter from "./../../../../models/journey-faults-counter.model";

// services
import { searchForJourneyFaults } from "./../../../../_helpers/search-for-journey-faults";

export default function RTLatencyChart({
  data,
  bySeconds,
  ...props
}: {
  data: Array<TrainJourneysModel>;
  bySeconds: boolean; // true for departure, false for arrival
}) {
  const [seriesData, setSeriesData] = React.useState<Array<any>>([]);
  // const [chartAnnotations, setChartAnnotations] = React.useState<Array<object>>(
  //   []
  // );

  const xAxisTitle = bySeconds ? "Lateness" : "Right Time (RT)";
  const yAxisTitle = bySeconds ? "Lateness (secs)" : "Trains on time (%)";

  useEffect(() => {
    const arrData = data[0].stops.map(stop => 0);
    const depData = data[0].stops.map(stop => 0);
    const annotations = [];
    let journeyFaultsCounter: Array<JourneyFaultsCounter> = [];

    // For each journey we have
    data.forEach((journey: TrainJourneysModel) => {
      // For each station each journey has
      journey.stops.forEach((station, index: number) => {
        // Check if a fault was triggered between current station and next
        journeyFaultsCounter = searchForJourneyFaults(
          journey,
          index,
          journeyFaultsCounter
        );

        journeyFaultsCounter = journeyFaultsCounter.sort((a, b) =>
          a.stationIndex > b.stationIndex ? 1 : -1
        );

        if (!bySeconds) {
          // Check if delay in actualArrival or actualDeparture of more than 1 min
          if (station.departureDiff > 0) {
            depData[index] += 1;
          }
          if (station.arrivalDiff > 0) {
            arrData[index] += 1;
          }
        } else {
          arrData[index] += station.arrivalDiff;
          depData[index] += station.departureDiff;
        }
      });
    });

    if (journeyFaultsCounter.length) {
      for (var i = 0, len = journeyFaultsCounter.length; i < len; i++) {
        let journeyFaults = journeyFaultsCounter[i];
        let annotation = {
          x: data[0].stops[journeyFaults.stationIndex].name,
          offsetX: 15,
          strokeDashArray: 3,
          borderColor: "red",
          label: {
            orientation: "horizontal",
            borderColor: "red",
            offsetX: 15,
            style: {
              color: "#fff",
              background: "red"
            },
            text: `${journeyFaults.faultsCounter}`
          }
        };

        annotations.push(annotation);
      }
    }

    // setChartAnnotations(annotations);

    if (bySeconds) {
      setSeriesData([
        {
          name: "Arrival",
          data: arrData.map(value => (value ? value / data.length : null))
        },
        {
          name: "Departure",
          data: depData.map(value => (value ? value / data.length : null))
        }
      ]);
    } else {
      setSeriesData([
        {
          name: "Arrival",
          data: arrData.map(value =>
            value ? (100 * value) / data.length : null
          )
        },
        {
          name: "Departure",
          data: depData.map(value =>
            value ? (100 * value) / data.length : null
          )
        }
      ]);
    }
  }, [data, bySeconds]);

  const chart = {
    options: {
      legend: {
        position: "top" as "top",
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      },
      chart: {
        id: xAxisTitle
      },
      xaxis: {
        type: "category" as "category",
        categories: data[0].stops.map(stops => stops.name),
        labels: {
          show: true,
          rotate: -90,
          rotateAlways: true,
          hideOverlappingLabels: false
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          height: 1,
          width: "100%",
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0
        },
        tickPlacement: "between",
        position: "bottom",
        title: {
          text: xAxisTitle
        }
      },
      yaxis: {
        title: {
          text: yAxisTitle
        },
        labels: {
          formatter: (val: number) =>
            val !== null && val >= 0
              ? val.toFixed(0) + (bySeconds ? "s" : "%")
              : ""
        }
      },
      markers: {
        size: 4
      }
      // annotations: {
      //   position: "back",
      //   xaxis: chartAnnotations
      // }
    },
    series: seriesData
  };

  return (
    <Chart
      options={chart.options}
      series={chart.series}
      type="line"
      height="350"
    />
  );
}
