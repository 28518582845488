import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import DateFnsUtils from "@date-io/date-fns";

// material
import { Alert, AlertTitle } from "@material-ui/lab";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// components
import ControlledAutocompleteField from "../shared/formik/controlled.autocomplete";
import AutocompleteField from "../shared/formik/autocomplete";
import CheckBoxFieldWithLabel from "../shared/formik/checkbox";
import DatePickerField from "../shared/formik/date-picker";
import Dashboard from "./dashboard/dashboard";

// models
import SingleStationSearchResponse from "src/models/single-station-search-response.model";
import { Range } from "src/models/shared";

// services
import { stationsService } from "src/services/stations.service";
import { trainJourneysService } from "src//services/train-journeys.service";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    wrapper: {
      position: "relative"
    },
    buttonProgress: {
      position: "absolute",
      marginTop: 4,
      marginLeft: theme.spacing(2)
    },
    paper: {
      padding: theme.spacing(2)
      // textAlign: 'center',
    }
  })
);

export default function StationDashboard() {
  const classes = useStyles();
  let urlParams: any = useParams();

  const state = {
    firstStation: urlParams.station || "",
    lastStation: "",
    days: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false
    },
    checkedAll: true
  };

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [selectedStation, setSelectedStation] = React.useState<any>();
  const [stationResponce, setStationResponce] = React.useState<
    SingleStationSearchResponse
  >();

  const defaultStartDate = new Date();
  defaultStartDate
    .setDate(
      defaultStartDate.getDate() -
        (urlParams && urlParams.range === Range.monthly ? 32 : 8)
    )
    .toLocaleString("en-GB");

  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() - 1).toLocaleString("en-GB");

  const startEndDate = new Date();
  startEndDate.setDate(startEndDate.getDate() - 2).toLocaleString("en-GB");

  const minSelectDate = new Date();
  minSelectDate.setMonth(minSelectDate.getMonth() - 24).toLocaleString("en-GB");

  const daysOfTheWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const dayCheckboxes: any[] = [];

  for (const [index, value] of daysOfTheWeek.entries()) {
    dayCheckboxes.push(
      <Field
        key={index}
        name={"days[" + value + "]"}
        label={value}
        value={value}
        component={CheckBoxFieldWithLabel}
      />
    );
  }

  const stations = Object.entries(stationsService.getTrainStations());
  const stationOptions: any[] = [];
  for (let [key, value] of stations) {
    stationOptions.push({
      value: key,
      title: value
    });
  }

  // sort by title
  stationOptions.sort((a, b) => {
    return ("" + a.title).localeCompare(b.title);
  });

  const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime());
  const isValidDateRange = (d: any) => {
    const validateDate = new Date(d).setHours(0, 0, 0, 0);
    const minDate = new Date(minSelectDate).setHours(0, 0, 0, 0);
    const maxDate = new Date(defaultEndDate).setHours(0, 0, 0, 0);
    return validateDate >= minDate && validateDate <= maxDate;
  };

  const formRef = useRef<any>();
  useEffect(() => {
    if (urlParams && formRef) {
      formRef.current.submitForm();
    }
  }, [urlParams, formRef]);

  return (
    <div>
      {error && (
        <Alert severity="error" variant="filled">
          <AlertTitle>Error</AlertTitle>
          An error has occurred. Please try again, and if the problem persists,
          contact your help desk or system administrator.{" "}
          <p>
            {" "}
            (<strong>{error}</strong>)
          </p>
        </Alert>
      )}
      <h1>Station Dashboard</h1>
      <Typography variant="body2" gutterBottom>
        {/* Search for a station or a leg. */}
        Set station parameters
      </Typography>
      <Formik
        innerRef={formRef}
        initialValues={{
          firstStation: urlParams.station,
          lastStation: "",
          startDate: defaultStartDate,
          endDate: defaultEndDate,
          days: {
            Monday: true,
            Tuesday: true,
            Wednesday: true,
            Thursday: true,
            Friday: true,
            Saturday: true,
            Sunday: true
          }
        }}
        validate={values => {
          const errors: any = {};

          if (!values.startDate) {
            errors.startDate = "Required";
          } else if (
            !isValidDate(values.startDate) ||
            !isValidDateRange(values.startDate)
          ) {
            errors.startDate = true;
          }

          if (!values.firstStation) {
            errors.firstStation = "Required";
          }

          const days = Object.entries(values.days);
          let daysSet = false;
          for (let option of days) {
            if (option) {
              daysSet = true;
              break;
            }
          }

          if (!daysSet) {
            errors.days = "At least one day has to be selected";
          }

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setError(null);
          setStationResponce(undefined);

          // fix for station names
          values.firstStation = stationOptions.find(
            s => s.title === values.firstStation
          ).value;

          const params: any = { ...values };

          params.startDate = values.startDate.toISOString().split("T")[0];
          params.endDate = values.endDate.toISOString().split("T")[0];

          if (!params.lastStation) {
            delete params.lastStation;
          }

          const days = Object.entries(values.days);
          let daysString = "";
          for (let [key, value] of days) {
            if (value) {
              daysString = daysString.concat(" ", key);
            }
          }

          params.days = daysString.trim();

          setSelectedStation(
            stationOptions.find(s => s.value === params.firstStation)
          );

          if (!loading) {
            setLoading(true);

            trainJourneysService
              .getStopPatterns(params)
              .then((res: Array<SingleStationSearchResponse> | any) => {
                setSubmitting(false);
                setLoading(false);

                if (res.error) {
                  setError(res.error);
                } else {
                  setStationResponce(res);
                }
              });
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={12} sm={3}>
                  {urlParams.station && (
                    <Field
                      name="firstStation"
                      id="firstStation"
                      label="Station"
                      value={state.firstStation}
                      inputValue={state.firstStation}
                      options={stationOptions}
                      component={ControlledAutocompleteField}
                    />
                  )}
                  {!urlParams.station && (
                    <Field
                      name="firstStation"
                      id="firstStation"
                      label="Station"
                      value={state.firstStation}
                      options={stationOptions}
                      component={AutocompleteField}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="startDate"
                    id="startDate"
                    label="Start date"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={startEndDate}
                    component={DatePickerField}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Field
                    name="endDate"
                    id="endDate"
                    label="End date"
                    disableFuture="true"
                    minDate={minSelectDate}
                    maxDate={defaultEndDate}
                    component={DatePickerField}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Box mt={3}>
              <Typography variant="body2" gutterBottom>
                {/* Search for a station or a leg. */}
                Set week days
              </Typography>
              <Grid item xs={12} sm={12}>
                {dayCheckboxes}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedAll}
                      onChange={event => {
                        state.checkedAll = !state.checkedAll;
                        // setState(state)
                        dayCheckboxes.forEach(cb => {
                          setFieldValue(
                            cb.props.name,
                            event.target.checked,
                            false
                          );
                        });
                      }}
                      value="all"
                    />
                  }
                  label="All"
                />
                <p className="MuiFormHelperText-root Mui-error">
                  {errors.days}
                </p>
              </Grid>
            </Box>

            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={3}
            >
              <Grid item xs={12} sm={6}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {loading ? "Searching ..." : "Search"}
                </Button>

                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {stationResponce && (
        <Dashboard data={stationResponce} station={selectedStation} />
      )}
    </div>
  );
}
